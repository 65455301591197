<style scoped>
    .v-application--wrap {
        background-color: #e1e1e1;
    }
</style>
<template>
            <v-container class="m-sm-0 p-sm-0 fill-height">
                <v-row justify="center" class="mt-lg-5 mt-sm-0">
                    <v-col lg="4" md="6" sm="12">
                        <v-card class="mx-auto"
                                outlined v-if="waitingForfResp">
                            <v-card-title class="grey--text justify-center">A Push Notifcation Was Sent </v-card-title>
                            <div class="row grey--text justify-center">
                                <div class="col-md-1 text-center">
                                    <v-icon large>mdi-tablet-ipad</v-icon>
                                </div>
                                <div class="col-md-1 text-center">
                                    <v-expand-transition>
                                        <v-icon large v-show="isAuthorized" color="success">mdi-checkbox-marked-circle</v-icon>
                                    </v-expand-transition>
                                    <v-expand-transition>
                                        <v-icon large v-show="!isAuthorized && hasAuthorizationError" color="error">mdi-cancel</v-icon>
                                    </v-expand-transition>
                                    <v-progress-circular indeterminate v-show="waitingForfResp && !isAuthorized && !hasAuthorizationError" color="success"></v-progress-circular>
                                </div>
                                <div class="col-md-1 text-center">
                                    <v-icon large>mdi-lock</v-icon>
                                </div>

                            </div>
                            <v-card-text v-show="!loggingIn && hasAuthorizationError" color="#D50000" class="text-center">
                                <b style="color :#D50000 !important"> Authentication Failed</b>
                            </v-card-text>
                            <v-card-actions class="mx-auto" v-show="!loggingIn && hasAuthorizationError">
                                <v-btn outlined
                                       color="error"
                                       text v-on:click="RestForm">
                                    Cancel
                                </v-btn>
                                <v-btn outlined
                                       color="success"
                                       text v-on:click="sendPushNotification">
                                    Resend Notification
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card flat outlined elevation="10" v-show="!waitingForfResp && !hasAuthorizationError">
                            <v-card-title class="pb-0 justify-center">
                                <v-img class="mr-3" src="../assets/BelinoLogo.png" max-height="300" max-width="250">
                                </v-img>
                            </v-card-title>
                            <v-card-actions class="mx-auto mt-2" v-show="!loginSuccess">
                                <v-card-text class="text-center">
                                    <v-expand-transition>
                                        <v-icon large color="#D50000">mdi-cancel</v-icon>
                                    </v-expand-transition>    <b style="color :#D50000 !important"> Iogin Failed/Invalid Username or Password</b>
                                </v-card-text>
                            </v-card-actions>
                            <v-card-text>
                                <validation-observer ref="observer">
                                    <v-form @submit.prevent="submit">
                                        <validation-provider v-slot="{ errors }" name="Username" rules="required">
                                            <v-text-field label="Username"
                                                          v-model="userName"
                                                          required
                                                          :error-messages="errors"
                                                          prepend-icon="mdi-account-circle" />
                                        </validation-provider>
                                        <validation-provider v-slot="{ errors }" name="password" rules="required">
                                            <v-text-field label="Password"
                                                          prepend-icon="mdi-lock"
                                                          v-model="password"
                                                          :error-messages="errors"
                                                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                                          @click:append="showPass = !showPass"
                                                          :type="showPass ? 'text' : 'password'"
                                                          required />
                                        </validation-provider>
                                        <v-card-actions>
                                            <v-btn color="#1a237e" block class="white--text" type="submit" :loading="loggingIn" :disabled="loggingIn">
                                                Login
                                                <template v-slot:loader>
                                                    <span>
                                                        Signing In... <v-progress-circular indeterminate :disabled="true"
                                                                                           color="grey"></v-progress-circular>
                                                    </span>
                                                </template>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </validation-observer>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
        components: {
        ValidationProvider,
        ValidationObserver,
        }, inject: {
            theme: {
                default: { isDark: false },
            },
        },
  data: () => ({
    userName: '',
    password: null,
    showPass: false,
    isAuthorized: false,
    hasAuthorizationError: false,
    waitingForfResp: false,
    hasDuo: false,
    duoEnrollUrl:'',
    stateData: '',
    loggingIn: false,
    loginSuccess: true
        }),

  computed: {
    params() {
      return {
        userName: this.userName,
        password: this.password
      }
    }
  },
        methods: {
            RestForm: function () {
                this.waitingForfResp = false;
                this.loggingIn = false;
                this.hasAuthorizationError = false;
                this.isAuthorized = false;
            },
            sendPushNotification: function () {
                var self = this;
                self.hasAuthorizationError = false
                self.waitingForfResp = true;

                self.$http.post('/Auth/DuoPushNotification', { UserName: self.userName }).then(function (response) {
                   if (response.data.authorized) {
                       self.isAuthorized = true;
                       self.$store.commit('SetAuth', self.stateData);
                       self.waitingForfResp = false;
                       self.$router.push("/")
                   }
                   else {
                       self.loggingIn = false;
                       self.isAuthorized = false;
                       self.hasAuthorizationError = true;
                   }
               })
            },
           GetDuoAuthType :function () {
               var self = this;

               self.$http.post('/Auth/DuoAuthType', { UserName: self.userName }).then(function (response) {

                    if (response.data.duoResponse.response.result == "allow") {
                        self.$store.commit('SetAuth', self.stateData);
                        self.$router.push("/")
                    }

                    if (response.data.duoResponse.response.result == "auth") {
                        self.sendPushNotification();
                    }
                    else {
                        self.loginSuccess = false;
                        self.loggingIn = false
                        self.password = '';
                        self.$refs.observer.reset()
                    }
                })
            },

      async submit() {
      var self = this;
      const valid = await this.$refs.observer.validate()
      if (valid) {
        //this.login(this.params) // action to login
          self.loggingIn = true;
          self.loginSuccess = true;

          this.$http.post('/Auth/SignIn', { UserName: self.userName, Password: self.password}).then(function (response) {
              if (response.data.success) {
                  self.stateData = { token: response.data.tokenization.token, userName: self.userName, isCluster: response.data.user.isClusterLogin, locationsAccessId: response.data.user.locationAccessIds[0] };

                  if (response.data.user.isClusterLogin) {
                      self.$store.commit('SetAuth', self.stateData);
                      self.waitingForfResp = false;
                      self.isAuthorized = true;
                      self.$router.push("/InventoryPullSheets")
                  }
                  else {
                      if (response.data.requiresTwoFactor) {
                          self.GetDuoAuthType();
                      }
                      else {
                          self.isAuthorized = true;
                          self.$store.commit('SetAuth', self.stateData);
                          self.waitingForfResp = false;
                          self.$router.push("/")
                      }
                  }
              }
              else {
                  self.loginSuccess = false;
                  self.loggingIn = false
                  self.password = '';
                  self.$refs.observer.reset()
              }
          })
       }
    },
    clear() {
      // you can use this method to clear login form
      this.userName = ''
      this.password = null
      this.$refs.observer.reset()
    }
  }
}
</script>
