<script src="../plugins/chartjs-plugin-colorschemes.min.js"></script>

<script>
  //Importing Line class from the vue-chartjs wrapper
    import { Doughnut, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins;
  //Exporting this so it can be used in other components
  export default {
        extends: Doughnut,
        mixins: [reactiveProp],

    data () {
      return {
        //Chart.js options that controls the appearance of the chart
          options: {
              tooltips: {
                  mode: 'single',
                  callbacks: {
                      label: function (tooltipItems, data) {

                          var value = "";

                          if (tooltipItems.datasetIndex === 0) {
                              value = data.datasets[0].data[tooltipItems.index] / 100;
                          }

                          return currencyFormat(value);
                      }
                  }
              },


          legend: {
            display: true
          },
          responsive: true,
              maintainAspectRatio: false,
          fill: true
        }
      }
    },
    mounted () {
      //renderChart function renders the chart with the datacollection and options object.
        this.renderChart(this.chartData, this.options)
    }
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
</script>