<style>

    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 24px !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 0.700rem !important;
        height: 24px !important;
    }

    .v-chip.v-size--small {
        border-radius: 12px;
        font-size: 12px;
        height: 18px !important;
    }

    .v-data-table__wrapper > table > tbody > tr > td > .v-icon.v-icon {
        align-items: center;
        display: inline-flex;
        font-feature-settings: "liga";
        font-size: 17px !important;
        justify-content: center;
        letter-spacing: normal;
        line-height: 1;
        position: relative;
        text-indent: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .dz-remove {
        margin-top: 20px !important;
    }

    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 24px !important;
    }
</style>

<template>
    <v-container-fluid>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <v-card :loading="IsLoading">
                        <v-card-title class="justify-center">
                            <h4 class="headerFont mr-5 mb-1 text-center">
                                Inventory Item  Management
                            </h4>
                            <h4 class="mr-5 mb-1 text-center">
                                <v-chip color="black" small class="ml-5 white--text" v-if="ActiveWareHouse">
                                    {{ActiveWareHouse}}
                                </v-chip>
                            </h4>
                        </v-card-title>
                        <div class="row mb-0 mt-0">
                            <div class="col-md-12 p-0 m-0">
                                <v-toolbar flat dense>
                                    <v-toolbar-items>

                                        <v-select v-model="SelectedLocations"
                                                  :items="Locations"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  label="Select Ineventory Location"
                                                  dense
                                                  item-value="id"
                                                  class="mb-0 pb-0 mr-4 mt-1"
                                                  item-text="name"
                                                  v-on:change="!ShowRetailItems ? FilterOperationalItems(true) : FilterInventoryItems(true)">
                                        </v-select>

                                        <v-select v-model="SelectedItemCategories"
                                                  :items="ItemCategories"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  label="Select Category"
                                                  dense
                                                  item-value="itemCategoryId"
                                                  class="mb-0 pb-0 mr-4 mt-1"
                                                  item-text="itemTypeDescription"
                                                  multiple
                                                  v-on:change="!ShowRetailItems ? FilterOperationalItems(true) : FilterInventoryItems(true)"
                                                  clearable>
                                        </v-select>

                                        <v-select v-model="SelectedPriceType"
                                                  :items="PriceTypes"
                                                  :menu-props="{ maxHeight: '400' }"
                                                  label="Select Price Type"
                                                  dense
                                                  v-on:change="!ShowRetailItems ? FilterOperationalItems(true) : FilterInventoryItems(true)"
                                                  item-value="priceMatrixId"
                                                  class="mb-0 pb-0 mr-4 mt-1"
                                                  item-text="matrixDescription"
                                                  clearable
                                                  v-if="ShowRetailItems">
                                        </v-select>

                                        <v-text-field v-model="SearchFilter"
                                                      label="Search Items"
                                                      dense
                                                      class="mb-0 pb-0  mt-1"
                                                      hint="Filter Items Here"
                                                      v-on:input="!ShowRetailItems ? FilterOperationalItems(true) : FilterInventoryItems(true)"
                                                      clearable>

                                        </v-text-field>
                                    </v-toolbar-items>
                                    <v-btn color="primary"
                                           fab small class="ml-3 mb-3" outlined v-on:click="!ShowRetailItems ? FilterOperationalItems(false) : FilterInventoryItems(false)" :disabled="!SelectedLocations">
                                        <v-icon>mdi-text-search</v-icon>
                                    </v-btn>
                                    <div class="flex-grow-1"></div>
                                    <v-toolbar-items>

                                        <v-switch v-model="ShowPullSheetData"
                                                  label="Include Pull Sheet Info" class="mr-5 mt-3" color="black" :disabled="!SelectedLocations"></v-switch>

                                        <v-switch v-model="ShowRetailItems"
                                                  label="Retail Items" class="mr-5 mt-3" :disabled="!SelectedLocations"></v-switch>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon small class="ml-2" :disabled="SelectedInventoryItems.length == 0 && SelectedOperationalItems.length == 0" v-on:click=" !ShowRetailItems ? ShowOperationBulkEditModal() : ShowBulkEditModal()" v-on="on" v-bind="attrs">
                                                    <v-icon>mdi-file-edit-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Edit Item(s)</span>
                                        </v-tooltip>

                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon small class="ml-2" v-on="on" v-bind="attrs" v-on:click="ShowImportDialog">
                                                    <v-icon color="green">mdi-file-excel</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Sync Inventory Item(s)</span>
                                        </v-tooltip>

                                    </v-toolbar-items>
                                </v-toolbar>
                            </div>
                        </div>
                        <v-data-table :headers="InventoryItemHeaders"
                                      :items="InventoryItems"
                                      :items-per-page="23"
                                      class="elevation-1 mt-0 pt-0"
                                      item-key="key"
                                      show-select
                                      dense
                                      v-model="SelectedInventoryItems"
                                      v-if="InventoryItems.length != 0 && ShowRetailItems">


                            <template v-slot:header.includeInPullSheet="{ header }">
                                <b>{{header.text}}</b>
                                <v-chip color="warning" small class="white--text ml-5 mr-5" v-if="LocationPullSheetStatusInfo && ShowPullSheetData"> {{LocationPullSheetStatusInfo}}</v-chip>


                                <v-tooltip top v-if="ShowPullSheetData">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs"
                                               v-on="on" class="ml-5"  v-on:click="ShowUpdatePullSheetStatusModal">
                                            <v-icon>mdi-timeline-check-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Update Status</span>
                                </v-tooltip>

                                <v-tooltip top v-if="ShowPullSheetData">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs"
                                               v-on="on" class="ml-5">
                                            <v-icon>mdi-cloud-print-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Print Pull Sheet(s)</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.itemCategoryName="{ item }">
                                <v-chip inline color="#424242" small c outlined>
                                    {{item.itemCategoryName}}
                                </v-chip>
                            </template>

                            <template v-slot:item.itemName="{ item }">

                                <v-chip inline color="#3949AB" small class="white--text">
                                    {{item.itemName}}
                                </v-chip>
                                <v-chip inline color="#1E88E5" small class="ml-2" outlined>
                                    {{item.matrixDescription}}
                                </v-chip>
                            </template>

                            <template v-slot:item.startCount="{ item }">
                                <v-badge inline color="#9FA8DA" :content="!item.startCount ? '0' : item.startCount" small outlined>
                                </v-badge>
                            </template>

                            <template v-slot:item.startMinimum="{ item }">
                                <v-badge inline color="#1A237E" :content="!item.startMinimum ? '0' : item.startMinimum " small outlined>
                                </v-badge>
                            </template>
                            <template v-slot:item.stockCount="{ item }">
                                <v-badge inline color="#7986CB" :content="!item.stockCount ? '0' : item.stockCount" small outlined>
                                </v-badge>
                            </template>
                            <template v-slot:item.totalStock="{ item }">
                                <v-badge inline color="#009688" :content="!item.totalStock ? '0' : item.totalStock" small outlined>
                                </v-badge>
                            </template>


                            <template v-slot:item.sectionLocation="{ item }">
                                <v-chip inline color="primary" small>
                                    {{item.sectionLocation}} - {{item.sectionIsle}}
                                </v-chip>
                            </template>
                            <template v-slot:item.includeInPullSheet="{ item }">
                                <v-icon v-if="item.includeInPullSheet" color="success">
                                    mdi-book-check-outline
                                </v-icon>
                                <v-icon v-if="!item.includeInPullSheet" color="error" class="mr-5">
                                    mdi-null
                                </v-icon>

                                <v-chip v-show="ShowPullSheetData && item.includeInPullSheet" color="black" small class="ml-5 white--text">
                                    Start Loaded: {{item.startCountFulfilled}}
                                </v-chip>
                                <label v-show="ShowPullSheetData && item.includeInPullSheet" class="ml-2">|</label>
                                <v-chip v-show="ShowPullSheetData && item.includeInPullSheet" color="black" small class="ml-2" outlined>
                                    Stock Loaded: {{item.stockCountFulfilled}}
                                </v-chip>
                            </template>
                        </v-data-table>

                        <v-data-table :headers="OperationalItemHeaders"
                                      :items="OperationalInventoryItems"
                                      :items-per-page="23"
                                      class="elevation-1 mt-0 pt-0"
                                      item-key="key"
                                      show-select
                                      dense
                                      v-model="SelectedOperationalItems"
                                      v-if="!ShowRetailItems">

                            <template v-slot:header.includeInPullSheet="{ header }">
                                <b>{{header.text}}</b>
                                <v-chip color="warning" small class="white--text ml-5" v-if="LocationPullSheetStatusInfo && ShowPullSheetData"> {{LocationPullSheetStatusInfo}}</v-chip>
                                <v-tooltip top v-if="ShowPullSheetData">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs"
                                               v-on="on" class="ml-5" v-on:click="ShowUpdatePullSheetStatusModal">
                                            <v-icon>mdi-timeline-check-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Update Status</span>
                                </v-tooltip>

                                <v-tooltip top v-if="ShowPullSheetData">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs"
                                               v-on="on" class="ml-5">
                                            <v-icon>mdi-cloud-print-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Print Pull Sheet(s)</span>
                                </v-tooltip>

                            </template>

                            <template v-slot:item.qty="{ item }">
                                <v-chip color="#388E3C" small class="white--text">
                                    {{item.qty}}
                                </v-chip>
                            </template>
                            <template v-slot:item.includeInPullSheet="{ item }">
                                <v-icon v-if="item.includeInPullSheet" color="success">
                                    mdi-book-check-outline
                                </v-icon>
                                <v-icon v-if="!item.includeInPullSheet" color="error">
                                    mdi-null
                                </v-icon>
                                <v-chip v-show="ShowPullSheetData && item.includeInPullSheet" color="black" small class="ml-5" outlined>
                                    Qty Loaded: {{item.qtyFulfilled}}
                                </v-chip>
                            </template>
                        </v-data-table>
                        <v-alert icon="mdi-sitemap"
                                 prominent
                                 outlined
                                 elevation="4"
                                 type="primary"
                                 v-if="InventoryItems.length == 0 && OperationalInventoryItems.length == 0">
                            <strong>Select Location To Manage Inventory Items</strong>
                        </v-alert>
                    </v-card>
                </div>
            </div>
        </div>


        <v-dialog v-model="UpdatePullSheetStatusModal"
                  max-width="800" eager>
            <v-card :loading="IsBulkEditing">
                <v-card-title class="headline">
                    Update Pull Sheet Status
                </v-card-title>
                <div class="container">
                    <div class="row m-0">
                        <div class="col-md-12">
                            <v-select v-model="NewPulSheetStatusId"
                                      :items="BulkEdit.PullSheetStatuses"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select New Status"
                                      dense
                                      item-value="statusId"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="statusDescription">



                            </v-select>
                        </div>
                    </div>
                </div>

                <div class="container">

                    <div class="row m-0">
                        <div class="col-md-4">
                            <v-sheet>
                                <v-switch v-model="BulkEdit.ApplyGlobalSettings"
                                          inset
                                          label="Global Location Settings"></v-switch>
                            </v-sheet>
                        </div>

                        <div class="col-md-6 mt-4" v-show="BulkEdit.ApplyGlobalSettings">
                            <v-select v-model="BulkEdit.SelectedEditLocations"
                                      :items="BulkEditLocations"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Apply Items Settings To These Location(s)"
                                      dense
                                      multiple
                                      item-value="id"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="name"
                                      clearable>
                                <template v-slot:prepend-item>
                                    <v-list-item ripple
                                                 @click="toggle">
                                        <v-list-item-action>
                                            <v-icon :color="BulkEdit.SelectedEditLocations.length > 0 ? 'indigo darken-4' : ''">
                                                {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0"
                                          class="grey--text caption">
                                        ({{ BulkEdit.SelectedEditLocations.length  }} Locations Selected)
                                    </span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary"
                           @click="UpdatePullSheetStatusModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="UpdatePullSheetStatus" outlined>
                        Continue
                    </v-btn>
                </v-card-actions>
                <v-spacer></v-spacer>
            </v-card>
        </v-dialog>

        <v-dialog v-model="ImportDialog"
                  fullscreen eager>
            <v-card class="mx-auto" elevation="4" :loading="UploadInProgress" @close="ImportModalClosing()">
                <div class="col-md-10 col-sm-12 offset-lg-1">
                    <v-card-title class="grey--text">
                        <h5>Updated Item Inventory</h5>
                    </v-card-title>
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="DropzoneOptions" @vdropzone-error="errorUpload" @vdropzone-success="uploadSuccess" @vdropzone-processing="uploadSending()">


                    </vue-dropzone>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               @click="CloseImportModal">Close</v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="BulkEditModal"
                  max-width="800" eager>
            <v-card :loading="IsBulkEditing">
                <v-card-title class="headline">
                    Bulk Edit Inventory Item(s)
                </v-card-title>

                <div class="container">
                    <div class="col-md-12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Name
                                        </th>
                                        <th class="text-left">
                                            Price Type
                                        </th>
                                        <th class="text-left">
                                            Start
                                        </th>
                                        <th class="text-left">
                                            Stock
                                        </th>
                                        <th class="text-left">
                                            Minimum
                                        </th>
                                        <th class="text-left">
                                            Total
                                        </th>
                                        <th class="text-left">
                                            Location
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in SelectedInventoryItems"
                                        :key="item.key">
                                        <td>{{ item.itemName }}</td>
                                        <td>{{ item.matrixDescription }}</td>
                                        <td>{{ item.startCount }}</td>
                                        <td>{{ item.stockCount }}</td>
                                        <td>{{ item.startMinimum }}</td>
                                        <td>{{ item.totalCount }}</td>
                                        <td>{{ item.sectionLocation }} | {{item.sectionIsle}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>


                <v-spacer></v-spacer>

                <div class="container">
                    <div class="row">

                        <div class="col-md-4">

                            <v-checkbox v-model="BulkEdit.IncludeInPullSheet"
                                        label="Include In Pullsheet"></v-checkbox>
                        </div>
                        <div class="col-md-4">
                            <v-select v-model="BulkEdit.PriceType"
                                      :items="PriceTypes"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Price Type"
                                      dense
                                      item-value="priceMatrixId"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="matrixDescription"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-4">
                            <v-autocomplete v-model="BulkEdit.TotalCount"
                                            :items="IsleNumbers"
                                            outlined
                                            dense
                                            chips
                                            small-chips
                                            label="Total Count"></v-autocomplete>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <v-autocomplete v-model="BulkEdit.StartMinimum"
                                            :items="IsleNumbers"
                                            outlined
                                            dense
                                            chips
                                            small-chips
                                            label="Start Minimum"></v-autocomplete>

                        </div>

                        <div class="col-md-4">
                            <v-autocomplete v-model="BulkEdit.Section"
                                            :items="AlphabetItems"
                                            outlined
                                            dense
                                            chips
                                            small-chips
                                            label="Section"></v-autocomplete>
                        </div>

                        <div class="col-md-4">
                            <v-autocomplete v-model="BulkEdit.IsleNumber"
                                            :items="IsleNumbers"
                                            outlined
                                            dense
                                            chips
                                            small-chips
                                            label="Isle #/ Section Number"></v-autocomplete>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <v-sheet>
                                <v-switch v-model="BulkEdit.ApplyGlobalSettings"
                                          inset
                                          label="Global Location Settings"></v-switch>
                            </v-sheet>
                        </div>
                   </div>
                        <div class="row m-0">
                            <div class="col-md-6 mt-2" v-show="BulkEdit.ApplyGlobalSettings">
                                <v-select v-model="BulkEdit.SelectedWareHouseLocations"
                                          :items="WareHouseLocations"
                                          :menu-props="{ maxHeight: '400' }"
                                          label="Apply To These Warehouse Locations"
                                          dense
                                          multiple
                                          item-value="locationId"
                                          class="mb-0 pb-0 mr-4 mt-1"
                                          item-text="locationName"
                                          clearable>
                                </v-select>
                            </div>
                            <div class="col-md-6 mt-2" v-show="BulkEdit.ApplyGlobalSettings">
                                <v-select v-model="BulkEdit.SelectedEditLocations"
                                          :items="BulkEditLocations"
                                          :menu-props="{ maxHeight: '400' }"
                                          label="Apply Items Settings To These Location(s)"
                                          dense
                                          multiple
                                          item-value="id"
                                          class="mb-0 pb-0 mr-4 mt-1"
                                          item-text="name"
                                          clearable>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple
                                                     @click="toggle">
                                            <v-list-item-action>
                                                <v-icon :color="BulkEdit.SelectedEditLocations.length > 0 ? 'indigo darken-4' : ''">
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Select All
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0"
                                              class="grey--text caption">
                                            ({{ BulkEdit.SelectedEditLocations.length  }} Locations Selected)
                                        </span>
                                    </template>

                                </v-select>

                            </div>
                        </div>
                    </div>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               @click="BulkEditModal = false"
                               class="white--text">
                            Cancel
                        </v-btn>
                        <v-btn color="primary"
                               @click="BulkUpdateItems" outlined>
                            Continue
                        </v-btn>
                    </v-card-actions>
                    <v-spacer></v-spacer>
</v-card>
        </v-dialog>

        <v-dialog v-model="BulkOperationalEditModal"
                  max-width="800" eager>
            <v-card :loading="IsBulkEditing">
                <v-card-title class="headline">
                    Bulk Edit Operational Item(s)
                </v-card-title>

                <div class="container">
                    <div class="col-md-12">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Category
                                        </th>
                                        <th class="text-left">
                                            Name
                                        </th>
                                        <th class="text-left">
                                            Qty
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in SelectedOperationalItems"
                                        :key="item.operationalItemId">
                                        <td>{{ item.itemTypeDescription }}</td>
                                        <td>{{ item.itemDescription }}</td>
                                        <td>{{ item.qty }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <v-checkbox v-model="BulkEdit.IncludeInPullSheet"
                                        label="Include In Pullsheet"></v-checkbox>
                        </div>
                        <div class="col-md-8">
                            <v-autocomplete v-model="BulkEdit.Qty"
                                            :items="IsleNumbers"
                                            outlined
                                            dense
                                            chips
                                            small-chips
                                            label="Qty"></v-autocomplete>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-4">
                            <v-sheet>
                                <v-switch v-model="BulkEdit.ApplyGlobalSettings"
                                          inset
                                          label="Global Location Settings"></v-switch>
                            </v-sheet>
                        </div>
                    </div>

                    <div class="row m-0">
                        <div class="col-md-6 mt-2" v-show="BulkEdit.ApplyGlobalSettings">
                            <v-select v-model="BulkEdit.SelectedWareHouseLocations"
                                      :items="WareHouseLocations"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Apply To These Warehouse Locations"
                                      dense
                                      multiple
                                      item-value="locationId"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="locationName"
                                      clearable>
                            </v-select>
                        </div>
                        <div class="col-md-6 mt-2" v-show="BulkEdit.ApplyGlobalSettings">
                            <v-select v-model="BulkEdit.SelectedEditLocations"
                                      :items="BulkEditLocations"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Apply Items Settings To These Location(s)"
                                      dense
                                      multiple
                                      item-value="id"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="name"
                                      clearable>
                                <template v-slot:prepend-item>
                                    <v-list-item ripple
                                                 @click="toggle">
                                        <v-list-item-action>
                                            <v-icon :color="BulkEdit.SelectedEditLocations.length > 0 ? 'indigo darken-4' : ''">
                                                {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Select All
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0"
                                          class="grey--text caption">
                                        ({{ BulkEdit.SelectedEditLocations.length  }} Locations Selected)
                                    </span>
                                </template>

                            </v-select>

                        </div>
                    </div>
                </div>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="primary"
                               @click="BulkOperationalEditModal = false"
                               class="white--text">
                            Cancel
                        </v-btn>

                        <v-btn color="primary"
                               @click="BulkUpdateOperationalItems" outlined>
                            Continue
                        </v-btn>
                    </v-card-actions>
                    <v-spacer></v-spacer>
</v-card>
        </v-dialog>
        <v-snackbar v-model="SuccessSnack"
                    color="success" rounded top right>
            {{ SuccessMessage }}
        </v-snackbar>
        <v-snackbar v-model="ErrorSnack"
                    color="error" rounded top right>
            {{ ErrorMessage }}
        </v-snackbar>
    </v-container-fluid>
        </template>
    <script>
        import vue2Dropzone from 'vue2-dropzone'
        import 'vue2-dropzone/dist/vue2Dropzone.min.css'
        import store from '../plugins/store.js'
        // eslint-disable-next-line no-unused-vars
        export default {
            components: {
                vueDropzone: vue2Dropzone
            },
            data: () => ({
            UploadError: false,
            DropzoneOptions: {
               // url: 'https://localhost:44385/api/Inventory/SyncInventoryItems/',
                url: 'https://belinowebapi20210619035703.azurewebsites.net/api/Inventory/SyncInventoryItems/',
                method: 'POST',
                // thumbnailWidth: 150,
                maxFilesize: 0.5,
                acceptedFiles: 'application/vnd.ms-excel,application/msexcel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-xls,application/x-ms-excel',
                headers: { "Authorization": 'Bearer ' + store.getters.user.token },
                addRemoveLinks: true,
                useFontAwesome: false,
                timeout: 120000,
                duplicateCheck: true,
                dictDefaultMessage: "<span class='mdi mdi-cloud-upload mdi-24px mt-1'></span> <label class='mb-1'> UPLOAD FILE(S) </label>"
            },
            UploadedResponseHeaders: [
                { text: 'Item Name', value: 'itemName' },
                { text: 'Sku', value: 'sku' },
                { text: 'Action Type', value: 'actionType' },
                ],
            SelectedWareHouseLocation:[],
            ActiveWareHouse:'',
            ImportDialog: false,
            UpdatePullSheetStatusModal: false,
            LocationPullSheetStatusInfo:'',
            BulkOperationalEditModal: false,
            ShowRetailItems: true,
            ShowToolTip: false,
            SuccessSnack: false,
            ErrorSnack: false,
            SelectedLocations: '',
            SelectedPriceType: '',
            ErrorMessage: 'Error Updating Inventory Items',
            SuccessMessage: 'Successfuly Updated Inventory Items',
            SearchFilter: '',
            PullSheetStatuses: [],
            NewPulSheetStatusId:'',
            SelectedItemCategories: [],
            OperationalInventoryItems: [],
            SelectedOperationalItems:[],
            ItemCategories: [],
            Locations: [],
            UploadedItems: [],
            UploadInProgress: false,
            BulkEditLocations:[],
            PriceTypes: [],
            AlphabetItems: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
            IsleNumbers: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
            BulkEditModal: false,
            IsBulkEditing: false,
            WareHouseLocations: [],
                BulkEdit: {
                    ApplyGlobalSettings: false,
                    PriceType: '',
                    TotalCount: 0,
                    StartMinimum: 0,
                    Section: '',
                    IsleNumber: '',
                    SelectedEditLocations: [],
                    IncludeInPullSheet: '',
                    SelectedWareHouseLocations:[]
                },
            InventoryItems:[],
            rules: {
                required: value => !!value || 'Required.'
            },
            InventoryItemHeaders: [
               // { text: 'Warehouse Stock Location', value: 'warehouseName' },
                { text: 'Category', value: 'itemCategoryName' },
                { text: 'Item Name', value: 'itemName' },
               // { text: 'Price Type', value: 'priceTypeDescription' },
              //  { text: 'Price Type ', value: 'matrixDescription' },
                { text: 'Start Count', value: 'startCount' },
                { text: 'Stock Count', value: 'stockCount' },
                { text: 'Start Minimum', value: 'startMinimum' },
                { text: 'Total Count', value: 'totalStock' },
                //{ text: 'Adjusted Total Count', value: 'totalStock' },
                //{ text: 'Adjusted On', value: 'adjustedOn' },
                { text: 'Section', value: 'sectionLocation' },
                { text: 'Pull Sheet Info', value: 'includeInPullSheet', sortable: false},
                { text: '', value: 'data-table-select' }
            ],
            OperationalItemHeaders: [
                { text: 'Category', value: 'itemTypeDescription' },
                { text: 'Item Name', value: 'itemDescription' },
                //{ text: 'Fulfillment Description', value: 'fulfillmentDescription' },
                { text: 'Qty', value: 'qty' },
                { text: 'Pull Sheet Info', value: 'includeInPullSheet', sortable:false},
                { text: '', value: 'data-table-select' }
                ],
            ShowPullSheetData: false,
            SelectedInventoryItems:[],
            IsLoading: true,
            }),
            watch: {
                ShowRetailItems: {
                    handler() {

                        if (!this.ShowRetailItems) {
                            this.SelectedItemCategories = [];
                            this.SearchFilter = null;
                            this.LoadItemOperationalData();
                            this.ItemCategories = [];
                            this.selectedItems = [];
                            this.ItemCategories = [];
                            this.SelectedPriceType = null;

                        }
                        else {
                            this.SelectedOperationalItems = [];
                            this.LoadItemInventoryData();
                        }
                    }
                }
            },


            created() {
                this.LoadItemInventoryData();
            },
            computed: {
                SelectedAllLocations() {
                    return this.BulkEdit.SelectedEditLocations.length === this.Locations.length
                },
                IsAllSelected() {
                    return this.BulkEdit.SelectedEditLocations.length > 0 && !this.SelectedAllLocations
                },
                icon() {
                    if (this.SelectedAllLocations) return 'mdi-close-box'
                    if (this.IsAllSelected) return 'mdi-minus-box'
                    return 'mdi-checkbox-blank-outline'
                },
            },
            methods: {
                toggle() {
                    this.$nextTick(() => {
                        if (this.SelectedAllLocations) {
                            this.BulkEdit.SelectedEditLocations = []
                        } else {
                            this.BulkEdit.SelectedEditLocations = this.Locations.slice()
                        }
                    })
                },
                errorUpload: function () {
                    this.$refs.myVueDropzone.removeAllFiles()
                    this.UploadInProgress = false;
                    this.UploadError = true;
                    this.UploadedItems = [];
                },
                ShowImportDialog: function () {
                    this.UploadedItems = [];
                    this.ImportDialog = true;
                    this.UploadError = false;
                    this.$refs.myVueDropzone.removeAllFiles();
                    this.UploadInProgress = false;
                },
                uploadSending() {
                    this.UploadInProgress = true;
                    this.UploadError = false;
                    this.UploadedItems = [];
                },
                CloseImportModal() {
                    this.ImportDialog = false
                    this.$refs.myVueDropzone.removeAllFiles()
                },
                uploadSuccess(file, response) {
                    this.UploadInProgress = false;
                    this.$refs.myVueDropzone.removeAllFiles()
                    this.SuccessMessage = 'Sync Successful'
                    this.SuccessSnack = true;
                    if (!this.ShowRetailItems) {
                        this.FilterOperationalItems(false)
                    }
                    else {
                        this.FilterInventoryItems(false)
                    }
                },
                ShowUpdatePullSheetStatusModal: function () {
                    this.BulkEdit = {
                        SelectedEditLocations: [],
                        ApplyGlobalSettings: false,
                        PullSheetStatuses:[]
                    }
                    this.NewPulSheetStatusId = '';
                    this.BulkEditLocations = this.Locations.filter(x => x.id != this.SelectedLocations)
                    this.BulkEdit.PullSheetStatuses = this.PullSheetStatuses.filter(x => x.statusDescription != this.LocationPullSheetStatusInfo)
                    this.UpdatePullSheetStatusModal = true;
                },
                UpdatePullSheetStatus: function () {

                    var self = this;
                    self.IsBulkEditing = true;
                    var retailMerchantIds = [];

                    if (self.BulkEdit.ApplyGlobalSettings && self.BulkEdit.SelectedEditLocations.length != 0) {

                        for (var l = 0; l < self.BulkEdit.SelectedEditLocations.length; l++) {
                            var id = self.BulkEdit.SelectedEditLocations[l];
                            retailMerchantIds.push(id);
                        }
                    }

                    retailMerchantIds.push(self.SelectedLocations);
                    var retailMerchantIdsJoined = retailMerchantIds.join(',');


                    var data = {
                        LocationIds: retailMerchantIdsJoined,
                        PullSheetStatus: self.NewPulSheetStatusId
                    }

                    this.$http.post('/Inventory/UpdatePullSheetStatus', data).then(function (response) {
                        if (response.data.success) {
                            self.SuccessMessage = 'Successfully Updated Pull Sheet Status';
                            self.SuccessSnack = true;

                            if (self.ShowRetailItems) {
                                self.FilterInventoryItems(false);
                            }
                            else {
                                self.FilterOperationalItems(false);
                            }
                            self.IsBulkEditing = false;
                            self.UpdatePullSheetStatusModal = false;
                        }
                        else {
                            self.ErrorMessage = 'Error Updating Pull Sheet Status';
                            self.ErrorSnack = true;
                            self.IsBulkEditing = false;
                        }
                    })

                },
                ImportModalClosing: function () {
                    this.$refs.myVueDropzone.removeAllFiles()
                },
                BulkUpdateOperationalItems: function () {

                    var self = this;
                    self.IsBulkEditing = true;
                    var selectedItemIds = [];

                    var retailMerchantIds = [];

                    for (var i = 0; i < self.SelectedOperationalItems.length; i++) {

                        var itemId = self.SelectedOperationalItems[i].operationalItemId;
                        selectedItemIds.push(itemId);
                    }

                    //Add additional Retail Merchant Ids
                    if (self.BulkEdit.ApplyGlobalSettings && self.BulkEdit.SelectedEditLocations.length != 0) {

                        for (var l = 0; l < self.BulkEdit.SelectedEditLocations.length; l++) {
                            var id = self.BulkEdit.SelectedEditLocations[l];
                            retailMerchantIds.push(id);
                        }
                    }

                    retailMerchantIds.push(self.SelectedLocations);

                    var itemIdsJoined = selectedItemIds.join(',');
                    var retailMerchantIdsJoined = retailMerchantIds.join(',');
                    var warehouseLocationsJoined = self.BulkEdit.SelectedWareHouseLocations.length != 0 ? self.BulkEdit.SelectedWareHouseLocations.join(',') : null;

                    var data = {
                        ItemIds: itemIdsJoined,
                        ExistingRetailIds: retailMerchantIdsJoined,
                        Qty: self.BulkEdit.Qty,
                        IncludeInPullSheet: self.BulkEdit.IncludeInPullSheet,
                        BulkSetWareHouseLocations: warehouseLocationsJoined
                    }


                    this.$http.post('/Inventory/BulkUpdateOperationalInventoryItems', data).then(function (response) {
                        if (response.data.success) {
                            self.SuccessSnack = true;
                            self.FilterOperationalItems(false);
                            self.IsBulkEditing = false;
                            self.BulkOperationalEditModal = false;
                            self.SelectedOperationalItems = [];
                            self.BulkEdit.SelectedWareHouseLocations = [];
                        }
                        else {
                            self.ErrorSnack = true;
                            self.IsBulkEditing = false;
                        }
                    })
                },
                BulkUpdateItems: function () {

                    var self = this;
                    self.IsBulkEditing = true;
                    var selectedItemIds = [];

                    var retailMerchantIds = [];

                    for (var i = 0; i < self.SelectedInventoryItems.length; i++) {

                        var itemId = self.SelectedInventoryItems[i].itemId;
                        selectedItemIds.push(itemId);
                    }

                    //Add additional Retail Merchant Ids
                    if (self.BulkEdit.ApplyGlobalSettings && self.BulkEdit.SelectedEditLocations.length != 0) {

                        for (var l = 0; l < self.BulkEdit.SelectedEditLocations.length; l++) {
                            var id = self.BulkEdit.SelectedEditLocations[l];
                            retailMerchantIds.push(id);
                        }
                    }

                    retailMerchantIds.push(self.SelectedLocations);

                    var itemIdsJoined = selectedItemIds.join(',');
                    var retailMerchantIdsJoined = retailMerchantIds.join(',');
                    var warehouseLocationsJoined = self.BulkEdit.SelectedWareHouseLocations.length != 0 ? self.BulkEdit.SelectedWareHouseLocations.join(',') : null;

                    var data = {
                        ItemIds: itemIdsJoined,
                        ExistingRetailIds: retailMerchantIdsJoined,
                        IsleNumber: self.BulkEdit.IsleNumber,
                        Section: self.BulkEdit.Section,
                        StartMinimum: self.BulkEdit.StartMinimum,
                        TotalCount: self.BulkEdit.TotalCount,
                        PriceMatrixTypeId: self.BulkEdit.PriceType,
                        IncludeInPullSheet: self.BulkEdit.IncludeInPullSheet,
                        BulkSetWareHouseLocations: warehouseLocationsJoined,

                    }


                    this.$http.post('/Inventory/BulkUpdateInventoryItems',data).then(function (response) {
                        if (response.data.success) {
                            self.SuccessSnack = true;
                            self.FilterInventoryItems(false);
                            self.IsBulkEditing = false;
                            self.BulkEditModal = false;
                            self.SelectedInventoryItems = [];
                            self.BulkEdit.SelectedWareHouseLocations = [];
                        }
                        else {
                            self.ErrorSnack = true;
                            self.IsBulkEditing = false;
                        }
                    })
                },
                LoadItemInventoryData: function () {
                var self = this;
                this.$http.post('/Inventory/LoadInventoryItemManagementData', {}).then(function (response) {
                    if (response.data.success) {
                        self.Locations = response.data.accountLocations
                        self.PriceTypes = response.data.priceTypes;
                        self.ItemCategories = response.data.itemCategories;
                        self.PullSheetStatuses = response.data.pullSheetStatus;
                        self.IsLoading = false;
                        self.WareHouseLocations = response.data.wareHouseLocations;
                     }
                  })
                },
                LoadItemOperationalData: function () {
                    var self = this;
                    this.$http.post('/Inventory/LoadInventoryOperationalItemManagementData', { LocationIds: self.SelectedLocations}).then(function (response) {
                        if (response.data.success) {
                            self.ItemCategories = response.data.itemCategories;
                            self.IsLoading = false;
                            self.OperationalInventoryItems = response.data.inventoryItems
                        }
                    })
                },
                FilterOperationalItems: function (showLoader) {

                    var self = this;

                    if (!self.SelectedLocations)
                        return false;

                    self.SelectedOperationalItems = [];

                    self.IsLoading = showLoader;
                    var itemCategories = self.SelectedItemCategories.length != 0 ? self.SelectedItemCategories.join(',') : null;

                    this.$http.post('/Inventory/FilterOperationalItemManagementData',
                        {
                            Locations: self.Locations,
                            LocationIds: self.SelectedLocations,
                            SearchFilter: self.SearchFilter == '' ? null : self.SearchFilter,
                            ItemCategoreyIds: itemCategories
                        }).then(function (response) {
                            if (response.data.success) {
                                self.OperationalInventoryItems = response.data.inventoryItems
                                self.LocationPullSheetStatusInfo = response.data.getPullSheetStatus.pullSheetStatusDescription;
                                self.IsLoading = false;
                            }
                            else {
                                self.IsLoading = false;
                            }
                        })
                },
                ShowBulkEditModal: function () {
                this.BulkEdit = {
                    ApplyGlobalSettings: false,
                    PriceType: '',
                    TotalCount: 0,
                    Total: 0,
                    StartMinimum: 0,
                    Section: '',
                    IsleNumber: '',
                    IncludeInPullSheet:'',
                    SelectedEditLocations: [],
                    SelectedWareHouseLocations : []
                }

                this.BulkEditLocations = this.Locations.filter(x => x.id != this.SelectedLocations)
                this.BulkEditModal = true;
                },
                ShowOperationBulkEditModal: function () {

                  this.BulkEdit = {
                      Qty: 0,
                      SelectedEditLocations: [],
                      ApplyGlobalSettings: false,
                      IncludeInPullSheet: '',
                      SelectedWareHouseLocations: []
                  }

                  this.BulkEditLocations = this.Locations.filter(x => x.id != this.SelectedLocations)
                  this.BulkOperationalEditModal = true;
              } ,
                FilterInventoryItems: function (showLoader) {

                var self = this;

                    if (!self.SelectedLocations)
                        return false;

                self.SelectedInventoryItems = [];

                self.IsLoading = showLoader;
                var itemCategories = self.SelectedItemCategories.length != 0 ? self.SelectedItemCategories.join(',') : null;

                this.$http.post('/Inventory/FilterItemManagementData',
                    {
                        Locations: self.Locations,
                        LocationIds: self.SelectedLocations,
                        SearchFilter: self.SearchFilter == '' ? null : self.SearchFilter,
                        PriceMatrixId: self.SelectedPriceType == '' ? null : self.SelectedPriceType,
                        ItemCategoreyIds: itemCategories


                }).then(function (response) {
                    if (response.data.success) {
                        self.InventoryItems = response.data.inventoryItems;
                        self.IsLoading = false;
                        self.ActiveWareHouse = response.data.inventoryItems[0].warehouseName;
                        self.LocationPullSheetStatusInfo = response.data.getPullSheetStatus.pullSheetStatusDescription
                    }
                    else {
                        self.IsLoading = false;
                    }
                })
            }
        }
    }
        </script>
