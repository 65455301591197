
<template>
    <v-container v-cloak>
        <v-row class="p-0">
            <v-card justify="center" align="center" outlined class="col-md-6 fill-height mx-lg-auto my-lg-auto p-0" dense>
                <v-card-title class="justify-center">
                    <h4 class="headerFont mr-5 mb-1">
                        My Profile
                    </h4>
                </v-card-title>
                <v-col md-10 sm-12 class="p-0">
                    <v-icon x-large>
                        mdi-card-account-details-outline
                    </v-icon>

                    <v-chip color="black" class="ml-2 text-white" outlined>
                        {{User.firstName}} {{User.lastName}}
                    </v-chip>
                    <div class="col-md-2 mt-0 pt-0">
                        <v-switch v-model="EditMode"
                                  label="Edit"></v-switch>
                    </div>
                </v-col>
            </v-card>
        </v-row>
        <v-expand-transition>
            <v-row class="mt-1" v-if="!EditMode">
                <v-card outlined class="col-md-6 fill-height mx-lg-auto my-lg-auto" dense>
                    <v-list subheader
                            two-line dense>
                        <v-subheader inset class="justify-center"><h2>My Info</h2></v-subheader>

                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="grey lighten-1"
                                        dark>
                                    mdi-email
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>My Email</v-list-item-title>

                                <v-list-item-subtitle v-text="User.email"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="grey lighten-1"
                                        dark>
                                    mdi-account-settings
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>My Username</v-list-item-title>

                                <v-list-item-subtitle v-text="User.userName"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="grey lighten-1"
                                        dark>
                                    mdi-text-box-check
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>Account Status</v-list-item-title>

                                <v-list-item-subtitle>
                                    <v-chip :color="RenderUserStatusLabel(User.statusId)" small>
                                        {{User.statusDescription}}
                                    </v-chip>

                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon class="grey lighten-1"
                                        dark>
                                    mdi-hammer-wrench
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>My Roles</v-list-item-title>

                                <v-list-item-subtitle v-if="User.roles">
                                    <v-chip v-for="(value, index) in User.roles.split(',')" v-bind:key="index" small dense color="black" outlined class="ml-1">
                                        {{value.split(':')[1]}}
                                    </v-chip>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-row>
        </v-expand-transition>

        <v-expand-transition>
            <v-row class="mt-1" v-if="EditMode">
                <v-card justify="center" align="center" outlined class="col-md-6 fill-height mx-lg-auto my-lg-auto">

                    <validation-observer ref="observer">

                        <v-form @submit.prevent="submit" ref="form">
                            <v-container>

                                <v-alert dense
                                         outlined
                                         type="error"
                                         v-model="userTaken"
                                         dismissible>
                                    <strong>Username Is Taken Please Select Another Username</strong>
                                </v-alert>

                                <v-row>
                                    <v-col cols="12"
                                           md="4">
                                        <validation-provider v-slot="{ errors }" name="First Name" rules="required" v-if="User.LoginTypeId != 2000">
                                            <v-text-field v-model="User.firstName"
                                                          label="First name"
                                                          :error-messages="errors"
                                                          required></v-text-field>
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="12"
                                           md="4">
                                        <validation-provider v-slot="{ errors }" name="Last Name" rules="required" v-if="User.LoginTypeId != 2000">
                                            <v-text-field v-model="User.lastName"
                                                          label="Last name"
                                                          :error-messages="errors"
                                                          required></v-text-field>
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="12"
                                           md="4">
                                        <validation-provider v-slot="{ errors }" name="Email" rules="required|email" v-if="User.LoginTypeId != 2000">
                                            <v-text-field v-model="User.email"
                                                          label="E-mail"
                                                          :error-messages="errors"
                                                          required></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <v-row>

                                    <v-col cols="12"
                                           md="3">
                                        <validation-provider v-slot="{ errors }" name="Username" rules="required|min:5">
                                            <v-text-field v-model="User.userName"
                                                          label="Username"
                                                          :error-messages="errors"
                                                          required></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12"
                                           md="3">


                                        <validation-provider v-slot="{ errors}" name="Password" rules="validpassword">
                                            <v-text-field v-model="User.password"
                                                          label="Password"
                                                          :error-messages="errors"
                                                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                                          @click:append="showPass = !showPass"
                                                          :type="showPass ? 'text' : 'password'"
                                                          @focus="ShowValidationSummary"
                                                          @blur="HidePassWordValidationSummary"></v-text-field>
                                        </validation-provider>

                                    </v-col>


                                </v-row>
                            </v-container>

                            <v-card-actions class="justify-end">
                                <v-btn color="primary" outlined
                                       v-on:click="EditMode = false" typeof="button">Close</v-btn>
                                <v-btn color="primary"
                                       v-on:click="EditUser" :loading="EditingUser" :disabled="EditingUser">
                                    Save Changes
                                </v-btn>

                            </v-card-actions>
                        </v-form>
                    </validation-observer>
                </v-card>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row class="mt-1" v-if="EditMode && ShowPassValidationSummary">
                <v-card justify="center" align="center" outlined class="col-md-6 fill-height mx-lg-auto my-lg-auto">
                    <div class="col-md-12">
                        <v-alert dense>
                            <v-list dense>
                                <v-list-item-group>
                                    <v-list-item v-for="(item, index) in ValidationSummaryPanel" v-bind:key="index" class="p-0 m-0" dense>
                                        <v-list-item-icon>
                                            <v-icon v-text="item.Valid ? 'mdi-checkbox-marked-circle-outline' : 'mdi-minus-circle'" :color="item.Valid ? 'success' : 'error'" medium class="pt-0 pb-0 mb-0 mt-0"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content class="pt-0 pb-0 mb-0 mt-0">
                                            <v-list-item-title v-text="item.Text"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-alert>
                    </div>

                </v-card>
            </v-row>
        </v-expand-transition>

        <v-snackbar v-model="SuccessSnack"
                    color="success" rounded top right>
           Successfully Updated
        </v-snackbar>

        <v-snackbar v-model="ErrorSnack"
                    color="error" rounded top right>
          Error Updating

        </v-snackbar>
    </v-container>
    </template>

<script>

    import store from '../plugins/store.js';
    import { ValidationProvider, ValidationObserver, Validator } from 'vee-validate';

    /* eslint-disable no-useless-escape , vue/no-unused-components*/
    //Exporting this so it can be used in other components

    Validator.extend("validpassword", {

        getMessage: "Password Is Invaild",

        validate: value => new Promise(resolve => {
            var hasLowerCase = false;
            var hasUpperCase = false;
            var hasSpecialCharacter = false;
            var hasNumber = false;
            var hasLength = false;

            var result = false;

            hasLowerCase = IsValidExpression(value, "^(?=.*[a-z])");

            hasUpperCase = IsValidExpression(value, "^(?=.*[A-Z])");
            //customMessage = 'Must Contain 1 Uppercase Character'

            hasSpecialCharacter = IsValidExpression(value, "^(?=.*[!@#$%^&*])");
            //customMessage = 'Must Contain 1 Special Character'

            hasNumber = IsValidExpression(value, "^(?=.*[0-9])");
            //customMessage = 'Must Contain 1 Numeric Character'

            hasLength = IsValidExpression(value, "(?=.{8,})");

            result = hasLowerCase && hasUpperCase && hasSpecialCharacter && hasNumber && hasLength;
            resolve({
                valid: result
            });
        })
    });


    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        directives: {
            focus: {
                inserted: function (el) {
                    el.focus               // <======== changed this line
                }
            }
        },
        data: () => ({
            User: {
                password:null
            },
            StateData: {
            },
            EditingUser: false,
            EditMode: false,
            userTaken:false,
            SuccessSnack: false,
            ErrorSnack: false,
            ShowPassValidationSummary: false,
            showPass:false,
            ErrorMessage: 'Error Updating Info',
            SuccessMessage: 'Successfuly Updated Info',
            SelectedUserId: '',
            ValidationSummaryPanel: [{
                Text: 'Password Must Contain 1 Lowercase Character',
                Valid: false,
                RegEx: "^(?=.*[a-z])"
            },
            {
                Text: 'Password Must Contain 1 Uppercase Character',
                Valid: false,
                RegEx: "^(?=.*[A-Z])"
            },
            {
                Text: 'Password Must Contain 1 Special Character',
                Valid: false,
                RegEx: "^(?=.*[!@#$%^&*])"
            },
            {
                Text: 'Password Must Contain 1 Numeric Character',
                Valid: false,
                RegEx: "^(?=.*[0-9])"
            },
            {
                Text: 'Password Must Be At Least 8 Characters Long',
                Valid: false,
                RegEx: "(?=.{8,})"
            },
            ]
        }),
        watch: {
            User: {
                handler() {
                    for (var i = 0; i < this.ValidationSummaryPanel.length; i++) {
                        if (this.ValidationSummaryPanel[i].CharacterCount) {
                            this.ValidationSummaryPanel[i].Valid = this.User.passWord.length <= this.ValidationSummaryPanel[i].Amount
                        }
                        else {
                            this.ValidationSummaryPanel[i].Valid = IsValidExpression(this.User.password, this.ValidationSummaryPanel[i].RegEx) && this.User.password != ''
                        }
                    }
                },
                deep: true
            }
        },
        mounted: function () {
            this.LoadUserData();
        },
        methods: {
            LoadUserData: function () {
                var self = this;
                var userName = store.state.user.userName;
                this.$http.post('/UserManagement/FilterUsers', {SearchFilter: userName, UserRoles:[]}).then(function (response) {
                    if (response.data.success) {
                        self.User = response.data.activeUsers[0]
                        self.User.password = '';
                    }
                })
            },
            ShowValidationSummary: function () {
                this.ShowPassValidationSummary = true;
            },
            HidePassWordValidationSummary: function () {
                this.ShowPassValidationSummary = false;
                this.User.password = '';
            },
            IsItemValid: function (regex) {
                return IsValidExpression(this.User.PassWord, regex)
            },
            async EditUser() {

                this.userTaken = false;

                var self = this;
                const valid = await this.$refs.observer.validate();

                if (valid) {
                    this.EditingUser = true;
                    this.$http.post('/UserManagement/AddOrUpdateUser', { User: this.User }).then(function (response) {
                        if (response.data.success) {
                            self.EditingUser = false;
                            self.SuccessSnack = true;
                            self.EditMode = false;
                            self.StateData = { token: response.data.tokenization.token, userName: self.userName };
                            self.$store.commit('SetAuth', self.StateData);
                            self.$refs.observer.reset()
                        }
                        else {
                            self.EditingUser = false;

                            if (response.data.userExists) {
                                self.userTaken = true;
                            }
                            else {
                                self.ErrorSnack = true;
                                self.$refs.observer.reset()
                            }
                        }
                    })

                }
            },
            RenderUserStatusLabel: function (statusId) {
                var color = "success";

                if (statusId == 2000)
                    color = "red"
                if (statusId == 3000)
                    color = "info"

                return color;
            },
            RenderLoginTypeLabel: function (loginTypeId) {

                var color = "primary";

                if (loginTypeId == 2000)
                    color = "info"

                return color;
            },
        }
    }
    function IsValidExpression(pw, regex) {
        var regexExpression = new RegExp(regex);
        return regexExpression.test(pw)
    }
</script>