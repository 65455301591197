<style>
    .v-data-table__wrapper .v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-input--selection-controls v-input--switch error--text {
        margin-top: 0;
    }

    .v-input--selection-controls.v-input {
        flex: 0 1 auto;
        margin: 0;
    }
</style>
<template>
    <v-container-fluid>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <v-card :loading="IsLoading">
                        <v-card-title class="justify-center">
                            <v-select v-model="NewPulSheetStatusId"
                                      :items="PullSheetStatuses"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select New Status"
                                      dense
                                      item-value="statusId"
                                      class="mb-0 pb-0 mr-4 mt-5 mr-2"
                                      outlined
                                      v-on:change="ShowUpdatePullSheetStatusModal"
                                      item-text="statusDescription" v-if="SelectedLocation && CurrentPullSheetStatus != 1000">
                            </v-select>


                            <v-tooltip top v-if="InventoryItems.length !=0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs"
                                           v-on="on" class="ml-5" v-on:click="PrintPullSheetItems">
                                        <v-icon>mdi-cloud-print-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Print PullSheet(s)</span>
                            </v-tooltip>

                            <h4>
                                <v-chip :color="SetStatusColor()" small class="white--text ml-5 mb-3 mr-5" v-if="SelectedLocation != ''"> {{GetPullSheetStatusDescription()}}</v-chip>
                            </h4>
                            <h4 class="headerFont mr-5 mb-1 text-center">
                                Pull Sheet Fulfillment
                            </h4>
                        </v-card-title>

                        <div class="row mb-0 mt-0">
                            <div class="col-md-3  ml-1">
                                <v-select v-model="SelectedLocation"
                                          :items="RetailLocations"
                                          :menu-props="{ maxHeight: '400' }"
                                          label="Select Ineventory Location"
                                          dense
                                          item-value="id"
                                          class="mb-0 pb-0 mr-4 mt-1"
                                          item-text="name"
                                          outlined
                                          ref="LocationDropDown"
                                          required
                                          v-on:change="GetInventoryPullSheetInfo">
                                </v-select>
                            </div>

                            <div class="col-md-2 p-0 mt-1" eager v-if="!IsOperational">
                                <v-text-field label="Scan Items Here"
                                              outlined
                                              eager
                                              dense
                                              v-show="!DisableTextScan && !IsManual"
                                              autocomplete="off"
                                              ref="TextScan"
                                              @keydown.enter="SendBarcode"
                                              v-model="NewBarcode" clearable></v-text-field>
                            </div>

                            <div class="col-md-3 p-0 mt-1" v-if="SelectedLocation && IsManual && !IsOperational" eager>
                                <v-autocomplete outlined dense autocomplete="off"
                                                v-model="ManualBarCode"
                                                :items="InventoryItems"
                                                item-text="itemName"
                                                label="Search Items"
                                                item-value="itemId"
                                                openOnClick=false
                                                required
                                                clearable
                                                ref="DropDown"
                                                small-chips>
                                </v-autocomplete>
                            </div>

                            <div class="col-md-1 mt-1" v-if="!IsOperational">
                                <v-btn outlined color="primary" v-on:click=" SendManualItem" v-if="SelectedLocation && IsManual" :disabled="ManualBarCode == '' || !ManualBarCode">
                                    Add Item
                                </v-btn>
                            </div>

                            <div class="col-md-2" v-if="!IsOperational">
                                <v-switch v-model="IsManual"
                                          label="Manual Search"
                                          color="primary"
                                          hide-details v-if="SelectedLocation" class="mb-3 ml-1"></v-switch>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>
        </div>

        <div class="row" v-if="IsOperational && InventoryItems.length != 0">
            <div class="col-md-12 p-0 m-0" height="100%">
                <v-card-title class="justify-center">
                    <h4 class="headerFont mr-5 mb-1 text-center">
                        Operational Items

                        <v-tooltip top v-if="InventoryItems.length !=0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs"
                                       v-on="on" class="ml-5" v-on:click="PrintScannedItems">
                                    <v-icon>mdi-cloud-print-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Print Item(s)</span>
                        </v-tooltip>
                    </h4>
                </v-card-title>
                <v-data-table :headers="OperationalItemHeaders"
                              :items="InventoryItems"
                              :items-per-page="10"
                              class="elevation-1 mt-0 pt-0"
                              item-key="key"
                              dense
                              v-if="InventoryItems.length != 0">

                    <template v-slot:item.itemName="{ item }">
                        <v-chip color="success"
                                dark small>
                            {{ item.itemDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.isFulfilled="{ item }">
                        <v-switch v-model="item.isFulfilled"
                                  :label="item.isFulfilled ? 'Loaded' : 'Not Loaded'"
                                  :color="item.isFulfilled ? 'success' : 'error'"
                                  v-on:change="UpdateOperationFulfillmentStatus(item)"
                                  hide-details></v-switch>
                    </template>
                </v-data-table>
                <v-alert outlined color="primary" v-if="InventoryItems.length == 0">
                    <strong>No Operational Items</strong>
                </v-alert>
            </div>
        </div>

        <div class="row" v-if="!IsOperational">
            <div class="col-md-6 p-0 m-0" height="100%">
                <v-card-title class="justify-center">
                    <h4 class="headerFont mr-5 mb-1 text-center">
                        Scanned Items        <v-badge outlined small color="success" class="headline mr-5" :content="ScannedItems.length" v-if="ScannedItems.length != 0">
                        </v-badge>

                        <v-tooltip top v-if="ScannedItems.length !=0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs"
                                       v-on="on" class="ml-5" v-on:click="PrintScannedItems">
                                    <v-icon>mdi-cloud-print-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Print Item(s)</span>
                        </v-tooltip>
                    </h4>

                    <v-text-field label="Search Items Here"
                                  eager
                                  dense
                                  autocomplete="off"
                                  v-model="ScanSearch" clearable single-line class="mr-5 mt-4" v-if="ScannedItems.length != 0"></v-text-field>

                    <v-tooltip top v-if="ScannedItems.length != 0">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="black"
                                   outlined
                                   v-bind="attrs"
                                   v-on="on"
                                   filter="ScanSearch"
                                   class="mt-3"
                                   v-on:click="ShowScanDetails">
                                <v-icon>mdi-bullseye-arrow</v-icon>
                            </v-btn>
                        </template>
                        <span>See Scan Details</span>
                    </v-tooltip>
                </v-card-title>
                <v-data-table :headers="ScannedItemHeaders"
                              :items="ScannedItems"
                              :items-per-page="10"
                              class="elevation-1 mt-0 pt-0"
                              item-key="key"
                              dense
                              :search="ScanSearch"
                              v-if="ScannedItems.length != 0">

                    <template v-slot:item.itemName="{ item }">
                        <v-chip color="success"
                                dark small>
                            {{ item.itemName }}
                        </v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon color="error" v-on:click="DeleteScannedItem(item, false)">mdi-trash-can-outline</v-icon>
                    </template>
                </v-data-table>
                <v-alert outlined color="primary" v-if="ScannedItems.length == 0">
                    <strong>No Scanned Items</strong>
                </v-alert>
            </div>

            <div class="col-md-6 p-0 m-0" height="100%">
                <v-card-title class="justify-center">
                    <h4 class="headerFont mr-5 mb-1 text-center" color="error">
                        Over Scanned Items
                    </h4>
                </v-card-title>
                <v-data-table :headers="OverScannedItemHeaders"
                              :items="OverScannedItems"
                              :items-per-page="10"
                              class="elevation-1 mt-0 pt-0"
                              item-key="key"
                              dense
                              v-if="OverScannedItems.length != 0">
                    <template v-slot:item.itemName="{ item }">
                        <v-chip color="error"
                                dark small>
                            {{ item.itemName }}
                        </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="error" v-on:click="DeleteScannedItem(item, true)">mdi-trash-can-outline</v-icon>
                    </template>
                </v-data-table>
                <v-alert outlined color="primary" v-if="OverScannedItems.length == 0" stacked>
                    <strong>No Over Scanned Items</strong>
                </v-alert>
            </div>
        </div>

        <v-dialog v-model="UpdatePullSheetStatusModal"
                  max-width="800" eager>
            <v-card :loading="IsLoading">
                <v-card-title class="headline grey--text">
                    Update Pull Sheet Status
                </v-card-title>
                <div class="container">
                    <div class="row m-0">
                        <div class="col-md-12">
                            <h4>
                                Are You Sure You Want To Change Status To <v-chip outlined color="black" class="ml-1">
                                    {{NewPullSheetStatus}}
                                </v-chip>
                            </h4>
                        </div>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           @click="UpdatePullSheetStatusModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="UpdatePullSheetStatus" outlined>
                        Update Status
                    </v-btn>
                </v-card-actions>
                <v-spacer></v-spacer>
            </v-card>
        </v-dialog>
        <v-dialog v-model="ScanDetails"
                  max-width="1500" eager>
            <v-card>
                <v-card-title class="headline grey--text">
                    Scan Details

                    <v-text-field label="Search Items Here"
                                  eager
                                  dense
                                  autocomplete="off"
                                  v-model="ScanDetailSearch" clearable single-line class="ml-5 mt-4" v-if="ScannedItems.length != 0"></v-text-field>
                </v-card-title>
                <div class="container">
                    <div class="row m-0">
                        <div class="col-md-12">
                            <v-data-table :headers="ScannedItemDetailHeaders"
                                          :items="InventoryItems"
                                          :items-per-page="10"
                                          class="elevation-1 mt-0 pt-0"
                                          item-key="key"
                                          :search="ScanDetailSearch"
                                          dense>

                                <template v-slot:item.itemName="{ item }">
                                    <v-chip color="black"
                                            dark small>
                                        {{ item.itemName }}
                                    </v-chip>
                                </template>
                                <template v-slot:item.summaryScanned="{ item }">
                                    <v-chip :color="GetItemScannedLabelColor(GetItemSummaryScanned(item), GetItemSummaryRemaining(item) ) "
                                            dark small outlined>
                                        {{ GetItemSummaryScanned(item)}}
                                    </v-chip>
                                </template>
                                <template v-slot:item.summaryRemaining="{ item }">
                                    <v-chip :color="GetItemRemainingLabelColor(GetItemSummaryScanned(item), GetItemSummaryRemaining(item))"
                                            dark small class="ml-5">
                                        {{GetItemSummaryRemaining(item)}}

                                        <v-icon v-if="GetItemSummaryRemaining(item) < 0" color="error" class="ml-3">mdi-pac-man</v-icon>
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black"
                           @click="ScanDetails = false"
                           class="white--text">
                        Close
                    </v-btn>
                </v-card-actions>
                <v-spacer></v-spacer>
            </v-card>
        </v-dialog>


        <v-snackbar v-model="SuccessSnack"
                    color="success" rounded top right>
            {{ SuccessMessage }}
        </v-snackbar>
        <v-snackbar v-model="ErrorSnack"
                    color="error" rounded top right>
            {{ ErrorMessage }}
        </v-snackbar>


        <div class="row" style="display:none" id="print" v-html="PrintContent">

        </div>
    </v-container-fluid>


</template>



<script>
    import store from '../plugins/store.js';
    import printJS from 'print-js'

    //const options = {
    //    name: '_blank',
    //    specs: [
    //        'fullscreen=yes',
    //        'titlebar=yes',
    //        'scrollbars=yes'
    //    ],
    //    styles: [
    //        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    //        'https://unpkg.com/kidlat-css/css/kidlat.css'
    //    ],
    //    timeout: 1000, // default timeout before the print window appears
    //    autoClose: true, // if false, the window will not close after printing
    //    windowTitle: window.document.title, // override the window title
    //}

    //Vue.use(VueHtmlToPaper, options);

    // or, using the defaults with no stylesheet


    export default {

        data: () => ({
        ActiveWareHouse: '',
        NewPulSheetStatusId: '',
        NewPullSheetStatus: '',
        ScanSearch: '',
        ScanDetailSearch:'',
        UpdatePullSheetStatusModal: false,
        IsBulkEditing:false,
        IsManual: false,
        IsLoading: true,
        NewBarcode: '',
        PrintContent:'',
        IsOperational:'',
        DisableTextScan: true,
        SuccessSnack: false,
        ErrorSnack: false,
        ErrorMessage: '',
        ManualBarCode: '',
        ShowOperational: false,
        SuccessMessage: '',
        ActiveLocation: '',
        ScanDetails:false,
        ScanType: 1000,
        CurrentPullSheetStatus: '',
        PullSheetStatuses:[],
        SelectedLocation:'',
        RetailLocations: [],
        ScannedItems: [],
        ScanSummary: '',
        ItemsAndBarcodeInfo: [],
        OperationalItemHeaders: [
            { text: 'Category', value: 'itemTypeDescription' },
            { text: 'Item', value: 'itemDescription' },
            { text: 'Fulfillment Status', value: 'isFulfilled', Sortable: false },
        ],
        ScannedItemHeaders: [
            { text: 'Category', value: 'categoryName' },
            { text: 'Item', value: 'itemName' },
            { text: 'Barcode', value: 'barcode' },
            { text: 'Scan Date', value: 'scanDate' },
            { text: '', value: 'actions' }
        ],
        ScannedItemDetailHeaders: [
            { text: 'Category', value: 'itemCategoryName' },
            { text: 'Item', value: 'itemName' },
            { text: 'Start Count', value: 'startCount' },
            { text: 'Stock Count', value: 'stockCount' },
            { text: 'Items Scanned', value: 'summaryScanned' },
            { text: 'Amount Remaining', value: 'summaryRemaining' },
        ],
        OverScannedItemHeaders: [
            { text: 'Category', value: 'categoryName' },
            { text: 'Item', value: 'itemName' },
            { text: 'Barcode', value: 'barcode' },
            { text: 'Scan Date', value: 'scanDate' },
            { text: '', value: 'actions' }
        ],
        OverScannedItems: [],
        InventoryItems: []
        }),
        created() {
            this.LoadPullSheetFulfillmentData();
        },
        watch: {
            IsManual: function () {
                var self = this;
                this.NewBarcode = ''
                if (this.IsManual) {
                    this.ManualBarCode = '';
                    this.$nextTick()
                        .then(function () {
                            self.$refs.DropDown.focus();
                        })
                }
                else {
                    this.ManualBarCode = '';
                    this.$nextTick()
                        .then(function () {
                            self.$refs.TextScan.focus();
                        })
                }
            },
            CurrentPullSheetStatus: function () {
                this.SetOperationStatus()
            }
            },
        methods: {
            SetOperationStatus: function () {

                var self = this;
                        var fulfillmentId = self.PullSheetStatuses.filter(x => x.statusId == self.CurrentPullSheetStatus)[0].fulfillmentTypeId;

                        if (fulfillmentId == 3000)
                            self.IsOperational = true;
                        else
                            self.IsOperational = false;
            },
            ShowScanDetails: function (item) {
                this.ScanDetails = true
            },

            GetItemScannedLabelColor: function (ScannedAmount, AmountRemaining) {
                if (ScannedAmount == 0 && AmountRemaining > 0)
                    return 'error'
                if (ScannedAmount > 0 && AmountRemaining < 0)
                    return 'error'
                if (ScannedAmount > 0 &&  ScannedAmount == AmountRemaining)
                    return 'success'
                if (ScannedAmount > 0 &&  AmountRemaining == 0)
                    return 'success'
                if (ScannedAmount != 0 && AmountRemaining > 0)
                    return 'warning'
            },
            GetItemRemainingLabelColor: function (ScannedAmount, AmountRemaining) {
                if (ScannedAmount == 0 && AmountRemaining == 0)
                    return 'primary'
                if (ScannedAmount > 0 && ScannedAmount == AmountRemaining)
                    return 'success'
                if (ScannedAmount > 0 &&  AmountRemaining == 0)
                    return 'success'
                if (ScannedAmount > 0 &&  AmountRemaining > 0)
                    return 'warning'
                if (ScannedAmount == 0 && AmountRemaining > 0)
                    return 'error'
            },

            GetItemSummaryScanned: function (item) {
                var self = this;
                var fulfillmentId = self.PullSheetStatuses.filter(x => x.statusId == self.CurrentPullSheetStatus)[0].fulfillmentTypeId;

                if (fulfillmentId == 2000)
                    return item.startCountFulfilled;
                if (fulfillmentId == 1000)
                    return item.stockCountFulfilled
            },
            GetItemSummaryRemaining: function (item) {
                var self = this;
                var fulfillmentId = self.PullSheetStatuses.filter(x => x.statusId == self.CurrentPullSheetStatus)[0].fulfillmentTypeId;

                if (fulfillmentId == 2000)
                    return item.startRemaining;
                if (fulfillmentId == 1000)
                    return item.stockRemaining
            },
            LoadPullSheetFulfillmentData: function () {

                var self = this;
                var user = store.state.user;

                if (!store.state.user.isAuthenticated) {
                    self.$router.push("/")
                }

                this.$http.post('/Inventory/LoadPullSheetFulfillmentData', { wareHouseLocationId: store.state.user.locationAccessId}).then(function (response) {

                    self.$nextTick()
                        .then(function () {
                            self.$refs.TextScan.focus();
                        })

                    if (response.data.success) {
                        self.IsLoading = false;
                        self.RetailLocations = response.data.accountLocations;
                        self.ActiveWareHouse = response.data.wareHouseLocation.locationName;
                        self.PullSheetStatuses = response.data.pullSheetStatus;

                      }
                 })
            },
            DeleteScannedItem: function (item, isOverageDelete) {
                var self = this;
                this.$http.post('/Inventory/DeleteScannedItem', { ScanId: item.scanId }).then(function (response) {
                    if (response.data.success) {
                        self.$nextTick()
                            .then(function () {
                                self.$refs.TextScan.focus();
                            })

                        var index = null;

                        if (isOverageDelete) {
                            index = self.OverScannedItems.findIndex(x => x.scanId == item.scanId);
                            self.OverScannedItems.splice(index, 1);
                        }
                        else {
                            index = self.ScannedItems.findIndex(x => x.scanId == item.scanId);
                            self.ScannedItems.splice(index, 1);
                        }

                        var updatedItemLocId = response.data.updatedItem.locInventoryItemId;
                        var updateObject = self.InventoryItems.findIndex(c => c.locInventoryItemId === updatedItemLocId);

                        if (updateObject) {

                            self.InventoryItems[updateObject].startCountFulfilled = response.data.updatedItem.startCountFilled;
                            self.InventoryItems[updateObject].stockCountFulfilled = response.data.updatedItem.stockCountFilled;
                            self.InventoryItems[updateObject].startRemaining = response.data.updatedItem.startRemaining;
                            self.InventoryItems[updateObject].stockRemaining = response.data.updatedItem.stockRemaining;
                        }
                    }
                })
            },
            UpdateOperationFulfillmentStatus(item) {
                this.$http.post('/Inventory/UpdateOperationalInventoryItem', { LocationOperationalItemId: item.operationalItemmLocationId, IsFulfilled: item.isFulfilled }).then(function (response) {
                    if (response.data.success) {
                        self.InventoryItems = response.data.inventoryItems;
                    }
                })
            },
            GetPullSheetStatusDescription: function () {
                if (this.CurrentPullSheetStatus != '') {
                    return this.PullSheetStatuses.filter(x => x.statusId == this.CurrentPullSheetStatus)[0].statusDescription;
                }
                return 'Locading Status...';
            },
            GetOperationalPullSheetInfo: function () {
                var self = this;
                this.$http.post('/Inventory/LoadItemInventoryOperationalPulSheetData', { LocationIds: this.SelectedLocation }).then(function (response) {
                    if (response.data.success) {
                        self.InventoryItems = response.data.inventoryItems;
                    }
                })
            },
            GetInventoryPullSheetInfo: function () {
                var self = this;
                this.$http.post('/Inventory/GetCurrentPullSheetStatus', { LocationIds: this.SelectedLocation }).then(function (response) {
                    if (response.data.success) {
                        self.CurrentPullSheetStatus = response.data.currentPullSheetStatus;
                        self.SetOperationStatus();
                    }
                }).then(function () {

                    self.$nextTick()
                        .then(function () {
                            self.$refs.TextScan.focus();
                        })


                    var scanType = self.PullSheetStatuses.filter(x => x.statusId == self.CurrentPullSheetStatus)[0].fulfillmentTypeId;

                    if (!self.IsOperational) {
                        self.$http.post('/Inventory/LoadPullSheetRetailItems', { LocationIds: self.SelectedLocation, ItemType: scanType }).then(function (response) {
                            if (response.data.success) {
                                self.$nextTick()
                                    .then(function () {
                                        const editButtonRef = self.$refs.TextScan;
                                        editButtonRef.focus();
                                    })
                                self.InventoryItems = response.data.inventoryItems;
                                self.ItemsAndBarcodeInfo = response.data.itemsAndBarcodes;
                                self.ScannedItems = response.data.regularScannedItems;
                                self.OverScannedItems = response.data.overScannedItems;
                                self.DisableTextScan = false;

                            }
                            self.ScanSummary = response.data.scanSummary;
                        })
                    }
                    else {
                        self.GetOperationalPullSheetInfo()
                    }
                })

                this.ActiveLocation = this.RetailLocations.filter(x => x.id === this.SelectedLocation)[0].name
            },
            ShowUpdatePullSheetStatusModal: function () {
                this.NewPullSheetStatus = '';
                this.NewPullSheetStatus = this.PullSheetStatuses.filter(x => x.statusId == this.NewPulSheetStatusId)[0].statusDescription;
                this.UpdatePullSheetStatusModal = true;
            },
            UpdatePullSheetStatus: function () {

                var self = this;
                self.IsLoading = true;

                this.$http.post('/Inventory/UpdatePullSheetStatus', {
                    LocationIds: self.SelectedLocation,
                    PullSheetStatus: self.NewPulSheetStatusId}).then(function (response) {
                    if (response.data.success) {
                        self.UpdatePullSheetStatusModal = false;
                        self.GetInventoryPullSheetInfo();
                        self.IsLoading = false;
                        self.NewPulSheetStatusId = '';
                    }
                    else {
                        self.ErrorMessage = 'Error Updating Pull Sheet Status';
                        self.ErrorSnack = true;
                        self.IsLoading = false;
                        self.NewPulSheetStatusId = '';
                    }
                })
            },
            async SendManualItem() {

                var self = this;
                var isRetail = true;
                var isOverage = false;
                var getItem = self.InventoryItems.filter(x => x.itemId == self.ManualBarCode)[0];

                var data = {
                    Input: getItem.itemName,
                    ItemId: getItem.itemId,
                    LocationId: self.SelectedLocation,
                    ItemsAndBarcodes: self.ItemsAndBarcodeInfo,
                    InventoryItems: self.InventoryItems,
                    CurrentPullSheetStatus: self.CurrentPullSheetStatus,
                    PullSheetStatuses: self.PullSheetStatuses,
                }


                    await this.$http.post('/Inventory/AddPullSheetItem', data).then(function (response) {
                        if (response.data.success) {
                            var updateItem = self.InventoryItems.filter(x => x.locInventoryItemId == response.data.updatedItem.locInventoryItemId)[0];
                            updateItem.startCountFulfilled = response.data.updatedItem.startCountFulfilled
                            updateItem.stockCountFulfilled = response.data.updatedItem.stockCountFulfilled

                            if (response.data.regularScannedItems.length != 0) {
                                self.ScannedItems.unshift(response.data.regularScannedItems[0])
                            }
                            else {
                                self.OverScannedItems.unshift(response.data.overScannedItems[0])
                            }

                            self.CurrentPullSheetStatus = response.data.currentPsStatus;
                            self.NewBarcode = '';
                        }
                    })

                self.$refs.TextScan.focus();

            },
            SetStatusColor : function() {

                if (this.CurrentPullSheetStatus == 1000) {

                    return "#FF6F00";
                }

                if (this.CurrentPullSheetStatus == 2000) {

                    return "#607D8B";
                }
                if (this.CurrentPullSheetStatus == 3000) {

                    return "#FF9100";
                }
                if (this.CurrentPullSheetStatus == 4000) {

                    return "#E65100";
                }
                if (this.CurrentPullSheetStatus == 5000) {

                    return "#33691E";
                }
                if (this.CurrentPullSheetStatus == 6000) {

                    return "#1B5E20";
                }
                if (this.CurrentPullSheetStatus == 7000) {

                    return "#004D40";
                }
                if (this.CurrentPullSheetStatus == 8000) {

                    return "#006064";
                }
                if (this.CurrentPullSheetStatus == 9000) {

                    return "#00838F";
                }

                if (this.CurrentPullSheetStatus == 10000) {

                    return "#01579B";
                }

                return '##F57F17';
            },

            PrintPullSheetItems: function() {

                var self = this;
                self.PrintContent = '';

                const headers = {
                    responseType: 'blob',
                    timeout: 30000,
                }

                var ftype = self.PullSheetStatuses.filter(x => x.statusId == self.CurrentPullSheetStatus)[0].fulfillmentTypeId;

                var data = {
                    LocationName: self.ActiveLocation,
                    InventoryItems: self.InventoryItems,
                    PullSheetType: ftype
                }

                if (self.IsOperational) {
                    data.InventoryItems = [],
                    data.OperationalItems = self.InventoryItems
                }


                this.$http.post('/GenerateTemplates/GeneratePullSheetItems',  data, {
                    headers: headers
                }).then(function (response) {

                    printJS({
                        printable: response.data,
                        type: 'raw-html',
                        css: ['https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css', 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js'],
                        header: 'Location Items',
                        documentTitle: 'Location Scanned Items'
                    })
                })
            },

            async PrintScannedItems() {

                var self = this;

                var data = {
                    LocationName: self.ActiveLocation,
                    ScannedItems: self.ScannedItems
                }

                if (self.IsOperational) {
                    data.OperationalItems = self.InventoryItems.filter(x => x.isFulfilled);
                    data.ScannedItems = [];
                 }

                data.PullSheetType = self.PullSheetStatuses.filter(x => x.statusId == self.CurrentPullSheetStatus)[0].fulfillmentTypeId;

                    self.PrintContent = '';

                    const headers = {
                        responseType: 'blob',
                        timeout: 30000,
                    }


                    await this.$http.post('/GenerateTemplates/GenerateCustomerItems', data, {
                        headers: headers
                    }).then(function (response) {

                        printJS({
                            printable: response.data,
                            type: 'raw-html',
                            css: ['https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css', 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js'],
                            header: 'Location Items',
                            documentTitle: 'Location Scanned Items'
                        })
                    })

            },
         async SendBarcode() {
                var self = this;

                self.$nextTick(async () =>{

                    var scanItem = self.NewBarcode;

                    self.NewBarcode = '';

                        var data = {
                            Input: scanItem,
                            LocationId: self.SelectedLocation,
                            ItemsAndBarcodes: self.ItemsAndBarcodeInfo,
                            InventoryItems: self.InventoryItems,
                            CurrentPullSheetStatus: self.CurrentPullSheetStatus,
                            PullSheetStatuses: self.PullSheetStatuses,
                        }


                       await this.$http.post('/Inventory/AddPullSheetItem', data).then(function (response) {
                            if (response.data.success) {

                                self.$refs.TextScan.focus();
                                self.CurrentPullSheetStatus = response.data.currentPsStatus;
                                self.NewBarcode = '';

                                if (response.data.regularScannedItems.length != 0) {
                                    self.ScannedItems.unshift(response.data.regularScannedItems[0])

                                }
                                else {
                                    self.OverScannedItems.unshift(response.data.overScannedItems[0])
                                }



                                var updatedItemLocId = response.data.updatedItem.locInventoryItemId;
                                var updateObject = self.InventoryItems.findIndex(c => c.locInventoryItemId === updatedItemLocId);

                                if (updateObject) {

                                    self.InventoryItems[updateObject].startCountFulfilled = response.data.updatedItem.startCountFulfilled
                                    self.InventoryItems[updateObject].stockCountFulfilled = response.data.updatedItem.stockCountFulfilled
                                    self.InventoryItems[updateObject].startRemaining = response.data.updatedItem.startRemaining;
                                    self.InventoryItems[updateObject].stockRemaining = response.data.updatedItem.stockRemaining;
                                }

                            }
                        })
                })
             }
        }
    }



    function getRandomColor() {
        var color = 'rgba(';
        for (var i = 0; i < 3; i++) {
            color += Math.floor(Math.random() * 255) + ',';
        }
        color += ' 0.6' + ')'; // add the transparency
        return color;
    }
</script>
