
<style>

    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 24px !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-size: 0.700rem !important;
        height: 24px !important;
    }

    .v-chip.v-size--small {
        border-radius: 12px;
        font-size: 12px;
        height: 18px !important;
    }

    .v-data-table__wrapper > table > tbody > tr > td > .v-icon.v-icon {
        align-items: center;
        display: inline-flex;
        font-feature-settings: "liga";
        font-size: 17px !important;
        justify-content: center;
        letter-spacing: normal;
        line-height: 1;
        position: relative;
        text-indent: 0;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .dz-remove{
        margin-top:20px !important;
    }


    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 24px !important;
    }
</style>

<template>
    <v-container-fluid>
        <v-row class="mt-0">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <v-card :loading="IsLoading">
                            <v-card-title class="justify-center">
                                <h4 class="headerFont mr-5 mb-1">
                                    Item Management
                                </h4>
                            </v-card-title>
                            <div class="row mb-0 mt-0">
                                <div class="col-md-12 p-0 m-0">
                                    <v-toolbar flat dense>
                                        <v-toolbar-items>
                                            <v-select v-model="SelectedItemCategories"
                                                      :items="ItemCategories"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Select Category"
                                                      dense
                                                      item-value="itemCategoryId"
                                                      class="mb-0 pb-0 mr-4 mt-1"
                                                      item-text="itemTypeDescription"
                                                      v-on:change="!ItemizedView ? FilterOperationalItems() : FilterItems()"
                                                      multiple
                                                      :loading="IsLoading"
                                                      clearable>
                                            </v-select>

                                            <v-select v-model="SelectedItemStatus"
                                                      :items="ItemStatusItems"
                                                      :menu-props="{ maxHeight: '400'}"
                                                      label="Select Item Status"
                                                      dense
                                                      :loading="IsLoading"
                                                      class="mb-0 pb-0 mr-4 mt-1"
                                                      item-value="itemStatusId"
                                                      item-text="itemStatusDescription"
                                                      v-on:change="!ItemizedView ? FilterOperationalItems() : FilterItems()"
                                                      v-if="!ItemizedView"
                                                      clearable>
                                            </v-select>

                                            <v-select v-model="SelectedBarcodeType"
                                                      :items="BarcodeTypes"
                                                      :menu-props="{ maxHeight: '400'}"
                                                      label="Select Barcode Type"
                                                      dense
                                                      :loading="IsLoading"
                                                      class="mb-0 pb-0 mr-4 mt-1"
                                                      item-value="barcodeTypeId"
                                                      item-text="barcodeTypeDescription"
                                                      v-on:change="FilterItems"
                                                      v-if="ItemizedView"
                                                      clearable>
                                            </v-select>
                                            <v-text-field v-model="SearchFilter"
                                                          label="Search Items"
                                                          dense
                                                          class="mb-0 pb-0  mt-1"
                                                          hint="Filter Items Here"
                                                          clearable v-on:input="!ItemizedView ? FilterOperationalItems() : FilterItems()" v-on:paste="ItemizedView ? 'FilterOperationalItems' : FilterItems"></v-text-field>
                                        </v-toolbar-items>
                                        <v-btn color="primary"
                                               fab small class="ml-3 mb-3" v-on:click="!ItemizedView ? FilterOperationalItems() : FilterItems()" outlined>
                                            <v-icon>mdi-text-search</v-icon>
                                        </v-btn>


                                        <div class="flex-grow-1"></div>
                                        <v-toolbar-items>
                                            <v-switch v-model="ItemizedView"
                                                      label="Retail Items" class="mr-5 mt-3"></v-switch>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon small v-on="on" v-bind="attrs" class="ml-5" v-on:click="!ItemizedView ? ShowAddNewItemModal() : {}" :disabled="ItemizedView">
                                                        <v-icon>mdi-tray-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Item</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon small class="ml-2" :disabled="selectedItems.length == 0 && OperationalSelectedItems.length == 0" v-on="on" v-bind="attrs" v-on:click="!ItemizedView ? ShowOperationalBulkEditModal() : ShowBulkEditModal()">
                                                        <v-icon>mdi-file-edit-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Edit Item(s)</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="ItemizedView">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon small class="ml-2" v-on="on" v-bind="attrs" v-on:click="ShowImportDialog">
                                                        <v-icon color="green">mdi-file-excel</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Import Item(s)</span>
                                            </v-tooltip>

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon small color="error" class="ml-2" v-on="on" v-bind="attrs" :disabled="selectedItems.length == 0 && OperationalSelectedItems.length == 0" v-on:click="!ItemizedView ? ShowOperationalDeleteModal() : ShowDeleteModal()">
                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete Item(s)</span>
                                            </v-tooltip>
                                        </v-toolbar-items>
                                    </v-toolbar>

                                </div>
                            </div>

                            <v-data-table :headers="OperationalHeaders"
                                          :items="OperationalItems"
                                          :items-per-page="20"
                                          class="elevation-1 mt-0 pt-0"
                                          item-key="key"
                                          show-select
                                          dense
                                          v-model="OperationalSelectedItems"
                                          v-if="!ItemizedView">

                                <template v-slot:item.itemDescription="props">
                                    <v-edit-dialog :return-value.sync="props.item.itemDescription"
                                                   @save="UpdateItemName(props.item.operationalItemId,props.item.itemDescription)" large>
                                        {{props.item.itemDescription}}
                                        <template v-slot:input>
                                            <v-text-field v-model="props.item.itemDescription"
                                                          :rules="[rules.required]"
                                                          label="Edit Name"
                                                          single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>


                                <template v-slot:item.itemStatusDescription="{ item }">
                                    <v-chip :color="item.itemStatusDescription == 'Active' ? 'success' : 'error'" dense small class="white--text">
                                        {{item.itemStatusDescription}}
                                    </v-chip>
                                </template>

                                <template v-slot:item.itemCategoryDesc="{ item }">
                                    <v-badge inline color="primary" small :content="item.itemCategoryDesc">
                                    </v-badge>

                                </template>

                            </v-data-table>

                            <v-data-table :headers="ItemizedHeaders"
                                          :items="InventoryItems"
                                          :items-per-page="20"
                                          class="elevation-1 mt-0 pt-0"
                                          item-key="key"
                                          show-select
                                          dense
                                          v-model="selectedItems"
                                          v-if="ItemizedView">

                                <template v-slot:item.caseBarcode="props">
                                    <v-edit-dialog :return-value.sync="props.item.caseBarcode"
                                                   @save="UpdateCaseBarcode(props.item.masterVariationId,props.item.caseBarcode)" large>
                                        <v-badge color="black" dense small :content="props.item.caseBarcode != null ? props.item.caseBarcode : 'N/A'">
                                        </v-badge>

                                        <template v-slot:input>
                                            <v-text-field v-model="props.item.caseBarcode"
                                                          :rules="[rules.required]"
                                                          label="Edit Case Barcode"
                                                          single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>


                                <template v-slot:item.sku="props">
                                    <v-edit-dialog :return-value.sync="props.item.sku"
                                                   @save="UpdateSku(props.item.masterVariationId,props.item.sku, props.item.itemId)" large>
                                        <v-chip :color="( (!props.item.sku) || props.item.sku.includes('NOSKU') )? 'warning' : 'black'" dense small class="white--text">
                                            {{props.item.sku}}
                                        </v-chip>

                                        <template v-slot:input>
                                            <v-text-field v-model="props.item.sku"
                                                          :rules="[rules.required]"
                                                          label="Edit Sku"
                                                          single-line></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                                <template v-slot:item.itemTypeDescription="{ item }">
                                    <v-badge inline color="primary" :content="item.itemTypeDescription" small>
                                    </v-badge>
                                    <!--<v-chip small color="primary" class="ml-1" outlined>
                                  {{item.itemTypeDescription}}
        </v-chip>-->
                                </template>
                                <template v-slot:item.price="{ item }">
                                    <v-badge inline color="black" :content="CurrencyFormat(item.price)" small>
                                    </v-badge>
                                    <!--<v-chip small color="primary" class="ml-1" outlined>
                                  {{item.itemTypeDescription}}
        </v-chip>-->
                                </template>

                            </v-data-table>
                        </v-card>
                    </div>
                </div>
            </div>
        </v-row>


        <v-dialog v-model="ImportDialog"
                  fullscreen eager>
            <v-card class="mx-auto" elevation="4" :loading="UploadInProgress">
                <div class="col-md-10 col-sm-12 offset-lg-1">
                    <v-card-title class="grey--text">
                        <h5>Import New/Updated Items</h5>
                    </v-card-title>
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="DropzoneOptions" @vdropzone-error="errorUpload" @vdropzone-success="uploadSuccess" @vdropzone-processing="uploadSending()">


                    </vue-dropzone>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               @click="CloseImportModal">Close</v-btn>
                    </v-card-actions>

                    <div class="row">
                        <div class="col-md-12 col-sm-12" v-show="UploadedItems.length != 0">
                            <v-data-table :headers="UploadedResponseHeaders"
                                          :items="UploadedItems"
                                          :items-per-page="20"
                                          class="elevation-1 mt-0 pt-0"
                                          dense>

                                <template v-slot:item.sku="{ item }">
                                    <v-chip color="black" dense small class="white--text">
                                        {{item.sku}}
                                    </v-chip>
                                </template>

                            </v-data-table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12" v-show="SyncedAlready">
                            <v-alert color="blue"
                                     dense
                                     dismissible
                                     typeof="info"
                                     outlined
                                     text><strong>All Records Synced Already</strong></v-alert>
                        </div>
                        <div class="col-md-12 col-sm-12" v-show="UploadError">
                            <v-alert color="red"
                                     dense
                                     dismissible
                                     icon="mdi-exclamation-thick"
                                     outlined
                                     text><strong>Error Uploading Please Try Again</strong></v-alert>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="AddNewItemModal"
                  max-width="700" eager>
            <v-card :loading="BulkEditing" >
                <v-card-title class="headline">
                   Add New Item
                </v-card-title>
                <v-spacer></v-spacer>

                <div class="container">
                    <v-form ref="form"
                            v-model="AddNewItemValid"
                            lazy-validation>
                        <div class="row">
                            <div class="col-md-6">
                                <v-select v-model="BulkEditItemCategorey"
                                          :items="ItemCategories"
                                          :menu-props="{ maxHeight: '400' }"
                                          label="Select Category"
                                          dense
                                           :rules="[rules.required]"
                                          item-value="itemCategoryId"
                                          class="mb-0 pb-0 mr-4 mt-1"
                                          item-text="itemTypeDescription"
                                          clearable>
                                </v-select>
                            </div>

                            <div class="col-md-6">
                                <v-select v-model="BulkEditStatus"
                                          :items="ItemStatusItems"
                                          :rules="[rules.required]"
                                          :menu-props="{ maxHeight: '400'}"
                                          label="Select Item Status"
                                          dense
                                          class="mb-0 pb-0 mr-4 mt-1"
                                          item-value="itemStatusId"
                                          item-text="itemStatusDescription"
                                          clearable>
                                </v-select>
                            </div>
                            </div>
                        <div class="row mt-1">
                            <div class="col-md-6">
                                <v-text-field v-model="BulkEditItemName"
                                              label="Item Name"
                                              :rules="[rules.required]"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable></v-text-field>
                            </div>


                            <div class="col-md-6">
                                <v-text-field v-model="BulkEditInventory"
                                              label="Inventory Count"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable></v-text-field>
                            </div>
                        </div>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="red darken-1"
                                   @click="AddNewItemModal = false"
                                   class="white--text">
                                Cancel
                            </v-btn>

                            <v-btn color="primary"
                                   :disabled="!AddNewItemValid"
                                   @click="AddNewOperationItem">
                                Continue
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </div>

            </v-card>
        </v-dialog>


        <v-dialog v-model="BulkEditOperationalModal"
                  max-width="700">
            <v-card :loading="BulkEditing">
                <v-card-title class="headline">
                    Bulk Edit Item(s)
                </v-card-title>
                <v-spacer></v-spacer>

                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <v-select v-model="BulkEditItemCategorey"
                                      :items="ItemCategories"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Category"
                                      dense
                                      item-value="itemCategoryId"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="itemTypeDescription"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-4">
                            <v-select v-model="BulkEditStatus"
                                      :items="ItemStatusItems"
                                      :menu-props="{ maxHeight: '400'}"
                                      label="Select Item Status"
                                      dense
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-value="itemStatusId"
                                      item-text="itemStatusDescription"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-4">
                            <v-text-field v-model="BulkEditInventory"
                                          label="Inventory Count"
                                          dense
                                          class="mb-0 pb-0  mt-1"
                                          clearable></v-text-field>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Category
                                                </th>
                                                <th class="text-left">
                                                    Item Name
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in OperationalSelectedItems"
                                                :key="item.key">
                                                <td>{{ item.itemCategoryDesc }}</td>
                                                <td>{{ item.itemDescription }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </div>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1"
                           @click="BulkEditOperationalModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="BulkUpdateOperationalItems">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="BulkEditModal"
                  max-width="700">
            <v-card :loading="BulkEditing">
                <v-card-title class="headline">
                    Bulk Edit Item(s)
                </v-card-title>
                <v-spacer></v-spacer>

                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <v-select v-model="BulkItemCategory"
                                      :items="ItemCategories"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Category"
                                      dense
                                      item-value="itemCategoryId"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="itemTypeDescription"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-6">
                            <v-select v-model="BulkBarcodeType"
                                      :items="BarcodeTypes"
                                      :menu-props="{ maxHeight: '400'}"
                                      label="Select Barcode Type"
                                      dense
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-value="barcodeTypeId"
                                      item-text="barcodeTypeDescription"
                                      clearable>
                            </v-select>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4">
                            <v-text-field v-model="BulkBundleSize"
                                          label="Bundle Size"
                                          dense
                                          class="mb-0 pb-0  mt-1"
                                          hint="Filter Items Here"
                                          clearable></v-text-field>
                        </div>
                        <div class="col-md-4">
                            <v-text-field v-model="BulkIndividualSize"
                                          label="Individual Size"
                                          dense
                                          class="mb-0 pb-0  mt-1"
                                          clearable></v-text-field>
                        </div>
                        <div class="col-md-4">
                            <v-text-field v-model="BulkPrice"
                                          label="Price"
                                          dense
                                          class="mb-0 pb-0  mt-1"
                                          clearable></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Name
                                            </th>
                                            <th class="text-left">
                                                Sku
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in selectedItems"
                                            :key="item.key">
                                            <td>{{ item.itemName }}</td>
                                            <td>{{ item.sku }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>


                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1"
                           @click="BulkEditModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="BulkUpdateItems">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="OperationalDeleteModal"
                  max-width="500">
            <v-card :loading="DeleteInProgress">
                <v-card-title class="headline">
                    Delete Item(s)
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                    Are You Sure You Want To Delete These Item(s)?
                </v-card-text>

                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Category
                                            </th>
                                            <th class="text-left">
                                                Item Name
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in OperationalSelectedItems"
                                            :key="item.key">
                                            <td>{{ item.itemCategoryDesc }}</td>
                                            <td>{{ item.itemDescription }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </div>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1"
                           @click="OperationalDeleteModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="DeleteOperationalItems">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="DeleteModal"
                  max-width="500">
            <v-card :loading="DeleteInProgress">
                <v-card-title class="headline">
                    Delete Item(s)
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                    Are You Sure You Want To Delete These Item(s)?
                </v-card-text>
                <v-card-text>
                    <v-checkbox v-model="DeleteEntireItems"
                                label="Delete Entire Item(s) & Skus"></v-checkbox>
                </v-card-text>

                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Name
                                            </th>
                                            <th class="text-left">
                                                Sku
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in selectedItems"
                                            :key="item.sku">
                                            <td>{{ item.itemName }}</td>
                                            <td>{{ item.sku }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                    </div>
                </div>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1"
                           @click="DeleteModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="DeleteItems">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-snackbar v-model="SuccessSnack"
                    color="success" rounded top right>
            {{ SuccessMessage }}
        </v-snackbar>

        <v-snackbar v-model="ErrorSnack"
                    color="error" rounded top right>
            {{ ErrorMessage }}
        </v-snackbar>
    </v-container-fluid>
</template>

<script>

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import store from '../plugins/store.js'

    export default {
        components: {
            vueDropzone: vue2Dropzone
        },
        data: () => ({
            ItemizedView: true,
            LoginTypes: [],
            FormAction: '',
            AddNewItemModal: false,
            AddNewItemValid: true,
            BulkEditModal: false,
            SyncedAlready: false,
            UploadError:false,
            DropzoneOptions: {
               // url: 'https://localhost:44385/api/Inventory/UploadItems/',
                url: 'https://belinowebapi20210619035703.azurewebsites.net/api/Inventory/UploadItems',
                method:'POST',
               // thumbnailWidth: 150,
                maxFilesize: 0.5,
                acceptedFiles:'application/vnd.ms-excel,application/msexcel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-xls,application/x-ms-excel',
                headers: { "Authorization": 'Bearer ' + store.getters.user.token},
                addRemoveLinks: true,
                useFontAwesome: false,
                timeout:120000,
                duplicateCheck: true,
                dictDefaultMessage: "<span class='mdi mdi-cloud-upload mdi-24px mt-1'></span> <label class='mb-1'> UPLOAD FILE(S) </label>"
            },
            UploadedResponseHeaders: [
                { text: 'Item Name', value: 'itemName' },
                { text: 'Sku', value: 'sku' },
                { text: 'Action Type', value: 'actionType' },
            ],
            BulkItemCategory: '',
            BulkBarcodeType: '',
            BulkBundleSize: '',
            BulkIndividualSize: '',
            BulkPrice:'',
            UploadedItems: [],
            InventoryItems: [],
            OperationalHeaders: [
                    { text: 'Category', value: 'itemCategoryDesc' },
                    { text: 'Item Name', value: 'itemDescription' },
                    { text: 'Status', value: 'itemStatusDescription' },
                    //{ text: 'Size', value: 'size' },
                    { text: 'Total Inventory', value: 'totalInventoryCount' },
                    { text: '', value: 'data-table-select' }
            ],
            OperationalDeleteModal: false,
            OperationalSelectedItems:[],
            OperationalItems: [],
            SelectedItemStatus:'',
            ItemCategories: [],
            BarcodeTypes: [],
            ItemStatusItems:[],
            DeleteInProgress:false,
            DeleteEntireItems: false,
            BulkEditing: false,
            BulkEditOperationalModal: false,
            BulkEditItemCategorey: '',
            BulkEditInventory: '',
            BulkEditStatus:'',
            Accounts: [],
            DeleteModal: false,
            ImportDialog: false,
            SelectedBarcodeType: '',
            SelectedItemCategories: [],
            SelectedLocations:[],
            selectedItems: [],
            UserRoles: [],
            BulkUpdateDialog: false,
            SearchFilter: null,
            showPass: false,
            IsLoading: true,
            UserStatus: [],
            UserDialog: false,
            DialogRemoveUser:false,
            ActiveUsers: [],
            valid: true,
            BulkEditItemName:'',
            EditMode: false,
            Locations:[],
            SuccessSnack: false,
            ErrorSnack: false,
            rules: {
                required: value => !!value || 'Required.'
            },
            ErrorMessage: 'Error Removing User',
            SuccessMessage: 'Successfuly Removed User',
            expanded: [],
            UploadInProgress: false,
            ItemizedHeaders: [
                { text: 'Category', value: 'itemTypeDescription' },
                { text: 'Bundle(s)', value: 'bundleSize' },
                { text: 'Individual(s)', value: 'individualSize' },
                { text: 'Name', value: 'itemName' },
                { text: 'Sku', value: 'sku' },
                { text: 'Barcode Type', value: 'barcodeTypeCode' },
                { text: 'Case Barcode', value: 'caseBarcode'},
                { text: 'Price', value: 'price'},
                { text: '', value: 'data-table-select' }
            ],
    }),

        created: function () {
            this.LoadItemManagementData();
        },
        watch: {
            ItemizedView: {
                handler() {

                    if (!this.ItemizedView) {
                        this.SelectedItemCategories = [];
                        this.SearchFilter = null;
                        this.LoadOperationalData();
                        this.ItemCategories = [];
                        this.selectedItems = [];
                        this.ItemCategories = [];

                    }
                    else {
                        this.OperationalSelectedItems = [];
                        this.LoadItemManagementData();
                    }
                }
            }
        },
        methods: {
            LoadItemManagementData: function () {
                var self = this;
                this.$http.post('/Inventory/DefaultItemManagementData', {}).then(function (response) {
                    if (response.data.success) {
                        self.ItemCategories = response.data.itemCategories;
                        self.InventoryItems = response.data.catalogItems;
                        self.BarcodeTypes = response.data.barcodeTypes;
                        self.IsLoading = false;
                    }
                    else {
                        self.IsLoading = false;
                    }
                })
            },
            LoadOperationalData: function () {
                var self = this;
                this.$http.post('/Inventory/LoadOperationalData', {}).then(function (response) {
                    if (response.data.success) {
                        self.ItemCategories = response.data.itemCategories;
                        self.OperationalItems = response.data.catalogItems;
                        self.ItemStatusItems = response.data.itemStatusItems;
                        self.IsLoading = false;
                    }
                    else {
                        self.IsLoading = false;
                    }
                })
            },
            errorUpload: function () {
                this.$refs.myVueDropzone.removeAllFiles()
                this.UploadInProgress = false;
                this.UploadError = true;
                this.SyncedAlready = false;
                this.UploadedItems = [];
            },
            ShowImportDialog: function () {
                this.UploadedItems = [];
                this.ImportDialog = true;
                this.UploadError = false;
                this.SyncedAlready = false;
                this.$refs.myVueDropzone.removeAllFiles();
                this.UploadInProgress = false;
            },
            uploadSending() {
                this.UploadInProgress = true;
                this.UploadError = false;
                this.SyncedAlready = false;
                this.UploadedItems = [];
            },
            CloseImportModal() {
                this.ImportDialog = false
                this.UploadedItems = []
                this.$refs.myVueDropzone.removeAllFiles()
            },
            uploadSuccess(file, response) {
                this.SyncedAlready = response.uploadedItemResults.length == 0;

                if (!this.SyncedAlready) {
                    this.FilterItems();
                    this.UploadedItems = response.uploadedItemResults;
                }
                else {
                    this.UploadedItems = []
                }

                this.UploadInProgress = false;
                this.$refs.myVueDropzone.removeAllFiles()
            },
            ShowDeleteModal: function () {
                this.DeleteInProgress = false;
                this.DeleteModal = true;
            },
            ShowOperationalDeleteModal() {
                this.DeleteInProgress = false;
                this.OperationalDeleteModal = true;
            },
            DeleteItems: function () {
                var self = this;
                this.DeleteInProgress = true;
                this.$http.post('/Inventory/DeleteItems', {
                    DeleteItems: this.selectedItems,
                    DeleteAll: this.DeleteEntireItems
                }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Item(s) Successfully Removed"
                        self.SuccessSnack = true;
                        self.FilterItems();
                        self.DeleteModal = false;
                        self.DeleteInProgress = false;
                        self.DeleteEntireItems = false;
                        self.selectedItems = [];


                    }
                    else {
                        self.ErrorMessage = "Error Removing Item(s)"
                        self.ErrorSnack = true;
                        self.DeleteInProgress = false;
                        self.DeleteEntireItems = false;
                        self.DeleteModal = false;
                    }
                })

            },
            DeleteOperationalItems: function () {
                var self = this;
                this.DeleteInProgress = true;
                this.$http.post('/Inventory/DeleteOperationalItems', {
                    OperationalIds: this.OperationalSelectedItems.map(x => x.operationalItemId).join(','),
                }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Item(s) Successfully Removed"
                        self.SuccessSnack = true;
                        self.FilterOperationalItems();
                        self.OperationalDeleteModal = false;
                        self.DeleteInProgress = false;
                        self.OperationalSelectedItems = [];
                    }
                    else {
                        self.ErrorMessage = "Error Removing Item(s)"
                        self.ErrorSnack = true;
                        self.DeleteInProgress = false;
                        self.DeleteEntireItems = false;
                        self.DeleteModal = false;
                    }
                })

            },
            ShowBulkEditModal: function () {
                this.BulkIndividualSize = '';
                this.BulkBundleSize = '';
                this.BulkPrice = '';
                this.BulkItemCategory = '';
                this.BulkBarcodeType = '';
                this.BulkEditModal = true;
                this.BulkEditing = false;
            },
            ShowOperationalBulkEditModal: function () {
                this.BulkEditItemCategorey = '';
                this.BulkEditInventory = '';
                this.BulkEditStatus = '';
                this.BulkEditOperationalModal = true;
                this.BulkEditing = false;
            },
            ShowAddNewItemModal: function () {
                this.$refs.form.reset()
                this.$refs.form.resetValidation()
                this.BulkEditItemCategorey = '';
                this.BulkEditInventory = '';
                this.BulkEditStatus = 1000;
                this.BulkEditItemName = '';
                this.BulkEditing = false;
                this.AddNewItemModal = true;
            },
            AddNewOperationItem: function () {
                var self = this;


                var isValid = this.$refs.form.validate();

                if (!isValid) {
                    return false
                }
                else {
                    self.BulkEditing = true;
                    var newItems = [{
                        ItemStatusId: self.BulkEditStatus,
                        ItemDescription: self.BulkEditItemName,
                        TotalInventoryCount: self.BulkEditInventory,
                        ItemCategoryId: self.BulkEditItemCategorey
                    }]

                    this.$http.post('/Inventory/AddNewOperationalItem', { OperationalItems: newItems}).then(function (response) {
                        if (response.data.success) {
                            self.FilterOperationalItems();
                            self.SuccessMessage = "Successfully Added Item"
                            self.SuccessSnack = true
                            self.AddNewItemModal = false;
                            self.BulkEditing = false;
                        }
                        else {
                            self.BulkEditing = false;
                            self.ErrorMessage = "Successfully Added Item"
                            self.ErrorSnack = true
                        }
                    })
                }
            },
            BulkUpdateOperationalItems: function () {
                var self = this;
                this.BulkEditing = true;
                this.$http.post('/Inventory/BulkUpdateOperationalItems', {
                    ItemCategoryId: this.BulkEditItemCategorey,
                    InventoryCount: this.BulkEditInventory,
                    StatusId: this.BulkEditStatus,
                    OperationalItemIds: this.OperationalSelectedItems.map(x => x.operationalItemId).join(',')
                }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Item(s) Successfully Updated"
                        self.SuccessSnack = true;
                        self.FilterOperationalItems();
                        self.BulkEditItemCategorey = '';
                        self.BulkEditInventory = '';
                        self.BulkEditStatus = '';
                        self.OperationalSelectedItems = [];
                        self.BulkEditOperationalModal = false;
                        self.BulkEditing = false;
                    }
                    else {
                        self.ErrorMessage = "Error Updating Item(s)";
                        self.ErrorSnack = true;
                        self.BulkEditing = false;
                    }
                })
            },
            BulkUpdateItems: function () {
                var self = this;
                this.BulkEditing = true;
                this.$http.post('/Inventory/BulkUpdateItems', {
                    IndividualSize: this.BulkIndividualSize,
                    BundleSize: this.BulkBundleSize,
                    Price: this.BulkPrice,
                    ItemCategory: this.BulkItemCategory,
                    BarcodeType: this.BulkBarcodeType,
                    UpdateItems: this.selectedItems
                }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Item(s) Successfully Updated"
                        self.SuccessSnack = true;
                        self.FilterItems();
                        self.selectedItems = [];
                        self.BulkEditModal = false;
                        self.BulkEditing = false;
                    }
                    else {
                        self.ErrorMessage = "Error Updating Item(s)";
                        self.ErrorSnack = true;
                        self.DeleteEntireItems = false;
                        self.BulkEditing = false;
                    }
                })
            },
            FilterItems: function () {
                var self = this;
                var locations = this.SelectedLocations == '' ? null :  this.SelectedLocations.join(',');
                var itemCategories = this.SelectedItemCategories == '' ? null : this.SelectedItemCategories.join(',')
                var barcodeType = this.SelectedBarcodeType == '' ? null : this.SelectedBarcodeType;
                this.IsLoading = true;

                this.$http.post('/Inventory/FilterItemData', {
                    ItemCategoryIds: itemCategories,
                    AvailableLocationIds: locations,
                    BarcodeTypeIds: barcodeType,
                    SearchFilter: this.SearchFilter
                }).then(function (response) {
                    if (response.data.success) {
                        self.InventoryItems = response.data.catalogItems;
                        self.IsLoading = false;
                    }

                    self.IsLoading = false;
                })
            },
            FilterOperationalItems: function () {
                var self = this;
                var itemCategories = this.SelectedItemCategories == '' ? null : this.SelectedItemCategories.join(',')
                this.IsLoading = true;

                this.$http.post('/Inventory/FilterOperationalData', {
                    ItemCategoryIds: itemCategories,
                    ItemStatus: this.SelectedItemStatus,
                    SearchFilter: this.SearchFilter
                }).then(function (response) {
                    if (response.data.success) {
                        self.OperationalItems = response.data.catalogItems;
                        self.IsLoading = false;
                    }
                    self.IsLoading = false;
                })
            },
            UpdateItemName: function(operationalItemId, itemDescription) {

                if (itemDescription == '')
                    return false;
                var self = this;
                this.$http.post('/Inventory/UpdateOperationalItemName', {
                    ItemName: itemDescription,
                    OperationalItemId: operationalItemId
                }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Item Successfully Updated"
                        self.SuccessSnack = true;
                    }
                    else {
                        self.ErrorMessage = "Error Updating Item";
                        self.ErrorSnack = true;
                        self.FilterOperationalItems();
                    }
                })
            },
            MapAccountInfo: function (merchantId) {
                var merchantName = this.Accounts.filter(x => x.merchantId == merchantId)[0]
                return merchantName.substring(merchantName.indexOf('-') +1)
            },
            UpdateSku: function (masterVariationId,newSku, itemId){

                this.IsLoading = true;


                if (newSku == '' || newSku == null) {
                    this.FilterItems();
                    return false;
                }

                var self = this;
                this.$http.post('/Inventory/UpdateSku', { MasterVariationId: masterVariationId, ItemId: itemId, NewSku: newSku }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Successfully Updated Sku"
                        self.SuccessSnack = true
                        self.IsLoading = false;
                    }
                    else {
                        self.IsLoading = false;
                        self.ErrorMessage = "Error Updating Sku"
                        self.ErrorSnack = true
                    }
                })
            },
            UpdateCaseBarcode: function(masterVariationId, caseBarcode) {

                this.IsLoading = true;

                if (caseBarcode == '' || caseBarcode == null) {
                    this.FilterItems();
                    return false;
                }


                var self = this;
                this.$http.post('/Inventory/UpdateCaseBarcode', { MasterVariationId: masterVariationId, CaseBarcode : caseBarcode}).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = "Successfully Updated B arcode"
                        self.SuccessSnack = true
                        self.IsLoading = false;
                    }
                    else {
                        self.ErrorMessage = "Error Updating Barcode"
                        self.ErrorSnack = true
                        self.IsLoading = false;
                    }
                })
            },
            CurrencyFormat: function (number) {
                return currencyFormat(number)
            }
        }
    }

    function currencyFormat(num) {
        num = num == undefined ? '$0.00' : (num/100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return num;
    }
</script>