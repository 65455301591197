var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-container',[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error","dismissible":""},model:{value:(_vm.userTaken),callback:function ($$v) {_vm.userTaken=$$v},expression:"userTaken"}},[_c('strong',[_vm._v("Username Is Taken Please Select Another Username")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.User.LoginTypeId != 2000)?_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"First name","error-messages":errors,"required":""},model:{value:(_vm.User.FirstName),callback:function ($$v) {_vm.$set(_vm.User, "FirstName", $$v)},expression:"User.FirstName"}})]}}],null,false,2725538505)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.User.LoginTypeId != 2000)?_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Last name","error-messages":errors,"required":""},model:{value:(_vm.User.LastName),callback:function ($$v) {_vm.$set(_vm.User, "LastName", $$v)},expression:"User.LastName"}})]}}],null,false,1058674537)}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.User.LoginTypeId != 2000)?_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail","error-messages":errors,"required":""},model:{value:(_vm.User.Email),callback:function ($$v) {_vm.$set(_vm.User, "Email", $$v)},expression:"User.Email"}})]}}],null,false,1121248740)}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Login Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-0 pb-0",attrs:{"items":_vm.LoginTypes,"menu-props":{ maxHeight: '400' },"label":"Select Login Type","dense":"","item-value":"loginTypeId","error-messages":errors,"item-text":"loginTypeDescription","required":""},on:{"change":function($event){return _vm.AdjustLoginType()}},model:{value:(_vm.User.LoginTypeId),callback:function ($$v) {_vm.$set(_vm.User, "LoginTypeId", $$v)},expression:"User.LoginTypeId"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-0 pb-0",attrs:{"items":_vm.Roles,"menu-props":{ maxHeight: '400' },"label":"Select Role Type","dense":"","multiple":"","error-messages":errors,"item-value":"roleId","item-text":"roleName","required":""},model:{value:(_vm.User.UserRoles),callback:function ($$v) {_vm.$set(_vm.User, "UserRoles", $$v)},expression:"User.UserRoles"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-0 pb-0",attrs:{"items":_vm.UserStatus,"menu-props":{ maxHeight: '400' },"label":"Select User Status","dense":"","error-messages":errors,"item-value":"statusId","item-text":"userStatus","required":""},model:{value:(_vm.User.StatusId),callback:function ($$v) {_vm.$set(_vm.User, "StatusId", $$v)},expression:"User.StatusId"}})]}}])})],1)],1),_c('v-row',[(_vm.User.LoginTypeId == 2000)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Location Access","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-5 pb-0",attrs:{"items":_vm.Locations,"menu-props":{ maxHeight: '400' },"label":"Select Location(s) Access","dense":"","error-messages":errors,"item-value":"locationId","item-text":"locationName","multiple":"","required":""},model:{value:(_vm.User.LocationAccessIds),callback:function ($$v) {_vm.$set(_vm.User, "LocationAccessIds", $$v)},expression:"User.LocationAccessIds"}})]}}],null,false,3338325079)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","error-messages":errors,"required":""},model:{value:(_vm.User.UserName),callback:function ($$v) {_vm.$set(_vm.User, "UserName", $$v)},expression:"User.UserName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(_vm.User.Id == '')?_c('div',[_c('validation-provider',{attrs:{"name":"Password","rules":"validpassword|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","required":"","error-messages":errors,"append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.User.Password),callback:function ($$v) {_vm.$set(_vm.User, "Password", $$v)},expression:"User.Password"}})]}}],null,false,524385849)})],1):_vm._e(),(_vm.User.Id != '')?_c('div',[_c('validation-provider',{attrs:{"name":"Password","rules":"validpassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","error-messages":errors,"append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.User.Password),callback:function ($$v) {_vm.$set(_vm.User, "Password", $$v)},expression:"User.Password"}})]}}],null,false,298387046)})],1):_vm._e()]),(_vm.User.LoginTypeId != 2000)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-checkbox',{attrs:{"label":"Require Mobile Authentication"},model:{value:(_vm.User.MultiFactorEnabled),callback:function ($$v) {_vm.$set(_vm.User, "MultiFactorEnabled", $$v)},expression:"User.MultiFactorEnabled"}})],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","typeof":"button"},on:{"click":function($event){return _vm.$emit('CloseDialog')}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.CreatingUser,"disabled":_vm.CreatingUser},on:{"click":_vm.AddNewUser}},[_vm._v(" "+_vm._s(_vm.User.Action)+" ")])],1)],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-alert',{attrs:{"dense":"","elevation":"2"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.ValidationSummaryPanel),function(item,index){return _c('v-list-item',{key:index,staticClass:"p-0 m-0",attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pt-0 pb-0 mb-0 mt-0",attrs:{"color":item.Valid ? 'success' : 'error',"medium":""},domProps:{"textContent":_vm._s(item.Valid ? 'mdi-checkbox-marked-circle-outline' : 'mdi-minus-circle')}})],1),_c('v-list-item-content',{staticClass:"pt-0 pb-0 mb-0 mt-0"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.Text)}})],1)],1)}),1)],1)],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }