 <style>
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

     .v-application .primary--text {
         color: #424242 !important;
         caret-color: #9e9e9e !important;
     }

     .v-application .mr-n5 {
         margin-right: -160px !important;
     }

     .v-application .accent {
         background-color: rgba(0, 0, 0, 0.87) !important;
         border-color: rgba(0, 0, 0, 0.87) !important;
     }

     .v-application .accent--text {
         color: rgba(0, 0, 0, 0.87) !important;
         caret-color: rgba(0, 0, 0, 0.87) !important;
     }

     .v-application .indigo--text.text--darken-4 {
         color: black!important;
         caret-color: black !important;
     }
</style>

<template>
    <v-container fluid>
        <div class="row pb-0">
            <div class="col-md-3 mt-4 ml-lg-1 pb-0" style="padding-bottom:0">
                <v-select v-model="selectedAccounts"
                          :items="connectedAccounts"
                          :menu-props="{ maxHeight: '400' }"
                          label="Select Account(s)"
                          :loading="accountsLoading"
                          multiple
                          dense
                          item-text="friendlyName"
                          item-value="accountId"
                          v-on:change="GetAccountLocations">

                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0" dense>
                            <span>{{ item.friendlyName }}</span>
                        </v-chip>
                        <span v-if="index === 1"
                              class="grey--text caption">
                            (+{{ selectedAccounts.length - 1 }} others)
                        </span>
                    </template>

                    <template v-slot:prepend-item>
                        <v-list-item ripple
                                     @click="toggle">
                            <v-list-item-action>
                                <v-icon :color="selectedAccounts.length > 0 ? 'indigo darken-4' : ''">
                                    {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
            </div>
            <!--<div class="col-md-2 mt-1 text-center mr-n5 pr-n4" v-if="selectedAccounts.length != 0" >
            <v-switch v-model="allLocations"
                      label="Search All" class="mr-0 pr-0"></v-switch>
        </div>-->
            <div class="col-md-2 mt-4 ml-0 pl-0 pb-0" :disabled="locationStates.length  == 0 || allLocations" style="padding-bottom:0">
                <v-select v-model="accountLocations"
                                :items="locationStates"
                                :loading="statesLoading"
                                multiple
                                clearable
                                label="Select State(s)"
                                dense
                                item-text="address.administrative_district_level_1"
                                item-value="address.administrative_district_level_1"
                                v-on:change="GetAccountLocationsByState($event)">

                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0" dense>
                            <span>{{ item.address.administrative_district_level_1 }}</span>
                        </v-chip>
                        <span v-if="index === 1"
                              class="grey--text caption">
                            (+{{ accountLocations.length - 1 }} others)
                        </span>
                    </template>

                    <template v-slot:prepend-item>
                        <v-list-item ripple
                                     @click="toggleStates">
                            <v-list-item-action>
                                <v-icon :color="accountLocations.length > 0 ? 'indigo darken-4' : ''">
                                    {{ stateicon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
            </div>

            <div class="col-md-2 mt-4 pb-0" :disabled="locationCities.length == 0 || allLocations" style="padding-bottom:0">
                <v-select v-model="selectedCities"
                          :items="locationCities"
                          :menu-props="{ maxHeight: '400' }"
                          label="Select Cities"
                          :loading="citiesLoading"
                          dense
                          multiple
                          item-text="address.locality"
                          item-value="address.locality"
                          clearable
                          persistent-hint v-on:change="GetAccountLocationsByCity($event)">
                    <!-- HTML that describe how select should render items when the select is open -->


                    <template v-slot:selection="{ index }">
                        <v-chip v-if="index === 0" dense>
                            <span>({{ selectedCities.length }} - Selected)</span>
                        </v-chip>
                    </template>
                    <template v-slot:prepend-item>
                        <v-list-item ripple
                                     @click="toggleCities">
                            <v-list-item-action>
                                <v-icon :color="selectedCities.length > 0 ? 'indigo darken-4' : ''">
                                    {{ cityicon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                    </v-select>
            </div>
            <div class="col-md-2 mt-4 pb-0" :disabled="cityLocations.length == 0 || allLocations" style="padding-bottom:0">
                <v-select v-model ="selectedLocations"
                                       :items="cityLocations"
                                       :menu-props="{ maxHeight: '400' }"
                                       label="Select Locations"
                                       :loading="cityLocationsLoading"
                                       multiple
                                       dense
                                       item-text="name"
                                       item-value="id"
                                       clearable>

                    <template v-slot:selection="{index }">
                        <v-chip v-if="index === 0" dense>
                            <span>({{ selectedLocations.length }}) - Selected</span>
                        </v-chip>
                    </template>

                    <template v-slot:prepend-item>
                        <v-list-item ripple
                                     @click="toggleLocations">
                            <v-list-item-action>
                                <v-icon :color="selectedLocations.length > 0 ? 'indigo darken-4' : ''">
                                    {{ locationicon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Select All
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                    </v-select>
            </div>
            <div class="col-md-2 mb-4 pb-0" style="padding-bottom:0">
                <v-menu v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        ref="dateMenu"
                        max-width="290px"
                        min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateRangeText"
                                      label="Order Dates"
                                      prepend-icon="mdi-calendar"
                                      v-bind="attrs"
                                      clearable
                                      v-on="on"
                                      readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="dates"
                                   no-title
                                   range>
                        <v-spacer></v-spacer>
                        <v-btn text
                               color="primary"
                               @click="dateMenu = false">
                            Cancel
                        </v-btn>
                        <v-btn text
                               color="primary"
                               @click="$refs.dateMenu.save(dates)">
                            OK
                        </v-btn>
                    </v-date-picker>

                </v-menu>
            </div>

        </div>
        <div class="row pt-0 mt-0">
            <div class="col-md-12 pt-0 mt-0">
                <v-btn color="black" small dark block v-on:click="GetAccountOrders">
                    Search
                </v-btn>
            </div>
        </div>

        <v-row class="fill-height" align-content="center" justify="center" v-show="showLoader">
            <v-col class="text-center headerFont" cols="12">
                <b> Generating Report </b>
            </v-col>
            <v-col cols="6">
                <div class="text-center">
                    <div class="text-center">
                        <v-progress-linear indeterminate
                                           color="#455a64"
                                           :size="100">

                        </v-progress-linear>
                    </div>
                </div>
            </v-col>
        </v-row>

        <div class="row" v-if="report && !showLoader">
            <div class="col-md-12">
                <v-card elevation="4">
                    <v-spacer></v-spacer>
                    <v-card-title>
                        <v-text-field v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Search"
                                      single-line
                                      hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="headers"
                                  :items="report.reportItems"
                                  :items-per-page="15"
                                  class="elevation-1"
                                  :sort-by.sync="sortBy"
                                  :sort-desc.sync="sortDesc"
                                  :search="search"
                                  dense>

                        <template v-slot:item.grossSalesAmount="{ item }">
                            {{item.grossSales}}
                        </template>
                        <template v-slot:item.discountAndCompsAmount="{ item }">
                            {{item.discountAndComps}}
                        </template>
                        <template v-slot:item.refundsAmount="{ item }">
                            {{item.refunds}}
                        </template>
                        <template v-slot:item.giftCardAmount="{ item }">
                            {{item.giftCard}}
                        </template>
                        <template v-slot:item.otherAmount="{ item }">
                            {{item.other}}
                        </template>
                        <template v-slot:item.feesAmount="{ item }">
                            {{item.fees}}
                        </template>

                        <template v-slot:item.totalCollectedAmount="{ item }">
                            {{item.totalCollected}}
                        </template>
                        <template v-slot:item.creditCardSalesAmount="{ item }">
                            {{item.creditCard}}
                        </template>
                        <template v-slot:item.cashAmount="{ item }">
                            {{item.cash}}
                        </template>
                        <template v-slot:item.taxTotalAmount="{ item }">
                            {{item.taxTotal}}
                        </template>
                        <template v-slot:item.netSalesAmount="{ item }">
                            {{item.netSales}}
                        </template>


                        <template slot="body.append">
                            <tr>
                                <th colspan="5">
                                </th>
                            </tr>
                            <tr v-if="report">
                                <th class="text-right" style="background-color:lightgray"><b>Total</b></th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.grossSalesTotal}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.discountAndComps}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.refundsTotal}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.giftCardsTotal}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.otherTotal}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.feesTotal}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.totalCollected}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.creditCardSales}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.cashSales}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.taxTotal}}</i></b> </th>
                                <th style="background-color:lightgray"><b><i>{{report.reportTotals.netSalesTotal}}</i></b> </th>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </div>
        </div>


        <v-row v-show="!showLoader && !report" align-content="center" justify="center" v-else class="mt-5">
                <div class="col-md-10">
                    <v-card elevation="4">
                        <v-alert :value="true"
                                 color="black"
                                 icon="mdi-chart-pie"
                                 outlined>
                            No Report Generated
                        </v-alert>
                    </v-card>
                </div>
        </v-row>
    </v-container>
</template>

<script>



    export default {
        data() {
            return {
                connectedAccounts: [],
                selectedAccounts: [],
                locationStates: [],
                accountLocations: [],
                locationCities: [],
                selectedCities: [],
                cityLocations:[],
                selectedLocations:[],
                accountsLoading: true,
                statesLoading:true,
                citiesLoading:true,
                cityLocationsLoading: true,
                showLoader: false,
                report: '',
                defaultStart: '',
                defaultEnd: '',
                sortBy: 'locationName',
                sortDesc: false,
                allLocations: false,
                dates: [],
                search: '',
                dateMenu: false,
                headers: [
                    {
                        text: 'Location',
                        align: 'start',
                        sortable: true,
                        value: 'locationName',
                    },
                    {
                        text: 'Gross Sales',
                        align: 'start',
                        sortable: true,
                        value: 'grossSalesAmount',
                    },
                    { text: 'Discounts & Comps', value: 'discountAndCompsAmount' },
                    { text: 'Refunds', value: 'refundsAmount' },
                    { text: 'Gift Cards', value: 'giftCardAmount' },
                    { text: 'Other', value: 'otherAmount' },
                    { text: 'Fees', value: 'feesAmount' },
                    { text: 'Total Collected', value: 'totalCollectedAmount' },
                    { text: 'Card Sales', value: 'creditCardSalesAmount'},
                    { text: 'Cash Sales', value: 'cashAmount'},
                    { text: 'Taxes', value: 'taxTotalAmount'},
                    { text: 'Net Sales', value: 'netSalesAmount'}
                ]
            }
        },
        watch: {
            allLocations: function () {

                if (this.showLoader) {
                    this.selectedCities = [],
                        this.accountLocations = [],
                        this.locationStates = []
                } else {

                    if(this.selectedAccounts.length != 0)
                        this.accountLocations();
                }
            }
            //selectedAccounts: function () {
            //        this.GetAccountLocations();
            //},
            //locationStates: function () {
            //    this.GetAccountLocationsByState();
            //},
            //cityLocations: function () {
            //    this.GetAccountLocationsByCity()
            //}
        },
        computed: {
            dateRangeText() {
                return this.dates.join(' - ')
            },
            selectAllAccounts() {
                return this.selectedAccounts.length === this.connectedAccounts.length
            },
            selectedSomeAccounts() {
                return this.selectedAccounts.length > 0 && !this.selectAllAccounts
            },
           selectAllStates() {
               return this.accountLocations.length === this.locationStates.length
            },
            selectSomeStates() {
                return this.accountLocations.length > 0 && !this.selectAllStates
            },
            selectAllCities() {
                return this.selectedCities.length === this.locationCities.length
            },
            selectSomeCities() {
                return this.selectedCities.length > 0 && !this.selectAllCities
            },
            selectAllLocations() {
                return this.selectedLocations.length === this.cityLocations.length
            },
            selectSomeLocations() {
                return this.selectedLocations.length > 0 && !this.selectAllLocations
            },
            icon() {
                if (this.selectAllAccounts) return 'mdi-close-box'
                if (this.selectedSomeAccounts) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            stateicon() {
                if (this.selectAllStates) return 'mdi-close-box'
                if (this.selectSomeStates) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            cityicon() {
                if (this.selectAllCities) return 'mdi-close-box'
                if (this.selectSomeCities) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            locationicon() {
                if (this.selectAllLocations) return 'mdi-close-box'
                if (this.selectSomeLocations) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            }
        },
        mounted: function () {
            this.GetConnectedAccounts();
        },
        methods: {
            toggleOrder() {
                this.sortDesc = !this.sortDesc
            },
            GetConnectedAccounts: function () {
                var self = this;
                this.$http.post('/Accounts/GetAccounts', { StatusCode: this.statusCode, AccountId: this.accountId, AccountName: this.accountName }).then(function (response) {
                    self.connectedAccounts = response.data.accounts;
                    self.accountsLoading = false;
                    self.selectedAccounts = response.data.accounts.map(x => x.accountId)
                    self.GetAccountLocations();
                })
            },
            GetAccountLocations: function () {

                var self = this;

                this.$http.post('/Accounts/AccountLocations', { AccountIds: this.selectedAccounts}).then(function (response) {
                    self.locationStates = response.data.locationStates;
                    self.accountLocations = response.data.locationStates.map(a => a.address.administrative_district_level_1);
                    self.statesLoading = false;

                    self.locationCities = response.data.locationCities;
                    self.selectedCities = response.data.locationCities.map(a => a.address.locality);
                    self.citiesLoading = false;

                    self.cityLocations = response.data.locations;
                    self.selectedLocations = response.data.locations.map(a => a.id);
                    self.cityLocationsLoading = false;

                })
            },
            GetAccountLocationsByState: function () {
                var accountStates = this.accountLocations;

                var self = this;
                this.$http.post('/Accounts/LocationCities', { AccountStates: accountStates, AccountIds: this.selectedAccounts}).then(function (response) {
                    self.locationCities = response.data.accountCities;
                    self.selectedCities = response.data.accountCities.map(a => a.address.locality);
                    self.citiesLoading = false;

                }).then(function () {
                    self.GetAccountLocationsByCity();
                })
            },
            GetAccountLocationsByCity: function () {

                var self = this;
                this.$http.post('/Accounts/CityLocations', { AccountCities: this.selectedCities, AccountIds: this.selectedAccounts}).then(function (response) {
                    self.cityLocations = response.data.cityLocations;
                    self.selectedLocations = response.data.cityLocations.map(a => a.id);
                    self.cityLocationsLoading = false;
                })
            },
             GetAccountOrders: function () {
                 var self = this;
                 self.showLoader = true;

                 var selectedLocations = new Array();

                 for (var i = 0; i < this.selectedLocations.length; i++) {
                     var getLocation = self.cityLocations.filter(x => x.id == this.selectedLocations[i])[0];
                     selectedLocations.push(getLocation);
                 }

                 var data = {
                     AccountIds: this.selectedAccounts,
                     DateRange: this.dates,
                     AccountCities: this.selectedCities,
                     AccountStates: this.accountLocations,
                     Locations: selectedLocations
                 };

                 this.$http.post('/Accounts/Orders', data).then(function (response) {
                     if (response.data.success) {
                         self.showLoader = false;
                         self.report = response.data.report;
                     }
                     else {
                         self.showLoader = false;
                     }
                 }).catch(error => {
                     console.log(error)
                     self.showLoader = false;
                 })
            },

             toggle() {
                this.$nextTick(() => {
                    if (this.selectAllAccounts) {
                        this.selectedAccounts = []
                        this.GetAccountLocations();

                    } else {
                        this.selectedAccounts = this.connectedAccounts.map(x => x.accountId).slice()
                        this.GetAccountLocations();
                    }
                })
            },
            toggleStates() {
                this.$nextTick(() => {
                    if (this.selectAllStates) {
                        this.accountLocations = []
                        this.GetAccountLocationsByState();

                    } else {
                        this.accountLocations = this.locationStates.map(x => x.address.administrative_district_level_1).slice()
                        this.GetAccountLocationsByState();
                    }
                })
            },
            toggleCities() {
                this.$nextTick(() => {
                    if (this.selectAllCities) {
                        this.selectedCities = []

                    } else {
                        this.selectedCities = this.locationCities.map(a => a.address.locality).slice()
                    }
                })
            },
            toggleLocations() {
                this.$nextTick(() => {
                    if (this.selectAllLocations) {
                        this.selectedLocations = []


                    } else {
                        this.selectedLocations = this.cityLocations.map(a => a.id).slice()

                    }
                })
            }
        }
    }


 </script>