

<template >
    <v-container ref="Accounts">
        <v-card elevation="2"
                outlined
                tile>
            <v-col class="col-md-12">
                <v-card class="pa-2" flat>
                    <v-card-title class="justify-center" outline>
                        <h4 class="headerFont mr-5">
                         Synced Accounts
                        </h4>
                    </v-card-title>

                    <v-alert color="#2A3B4D"
                             dark
                             icon="mdi-information-outline"
                             border="left"
                             prominent v-if="connectedAccounts.length == 0">
                        There Are Currently No Accounts Added.
                    </v-alert>

                    <v-simple-table v-if="connectedAccounts.length != 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Account  Name
                                    </th>
                                    <th class="text-left">
                                        Status
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in connectedAccounts"
                                    :key="item.accountId">
                                    <td><b>{{ item.friendlyName }}</b></td>
                                    <td>
                                        <v-badge :color="getStatusColor(item.statusCode)"
                                                 :content="item.statusCode">
                                        </v-badge>
                                    </td>
                                    <td>
                                        <v-btn icon v-on:click="RemoveAccountConfirm(item.accountId)">
                                            <v-icon>mdi-trash-can-outline</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-card-actions class="justify-end">
                        <v-btn outlined
                               color="dark-grey"
                               v-on:click="GetSquareAuthUrl">
                            Import Account(s)
                            <v-icon class="ml-1">mdi-folder-multiple-plus-outline</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-card>

        <v-dialog v-model="dialogRemoveAccount"
                  persistent
                  max-width="400">
            <v-card>
                <v-card-title class="headline">
                    Remove Account
                </v-card-title>
                <v-card-text>This will disconnect the Square Account.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                           outlined
                           Normal
                           @click="RemoveAccountCancel">
                        Cancel
                    </v-btn>
                    <v-btn color="green darken-1"
                           text
                           outlined
                           @click="RemoveAccount">
                        Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>



<script>
    export default {
        data() {
            return {
                dialog: false,
                loading: false,
                authorizationUrl: '',
                squareAuthPage: '',
                connectedAccounts: [{}],
                accountId: null,
                statusCode: null,
                accountName: null,
                dialogRemoveAccount: false,
                removeId:''
            }
        },
        mounted(){
            this.GetConnectedAccounts()
        },
        methods: {
            GetSquareAuthUrl: function (){
                  this.$http.get('/Accounts/AuthLink', []).then(function (response) {
                  window.location.href = response.data.authLink;
             })
         },
         getStatusColor: function(statusCode){
            var color='green';
            if(statusCode != 'ACT')
                color = "red";

                return color;
            },
            RemoveAccountConfirm: function (accountId) {
                var self = this;
                self.removeId = accountId;
                self.dialogRemoveAccount = true;
            },
            RemoveAccount: function () {
                var self = this;

                this.$http.delete('/Accounts/RemoveAccount', { data: { AccountId: self.removeId } }).then(function (response) {
                    if (response.data.success)
                    {
                        var deleteIndex = self.connectedAccounts.map(function (e) { return e.accountId; }).indexOf(self.removeId);
                        self.$delete(self.connectedAccounts, deleteIndex);
                    }
                })

                self.dialogRemoveAccount = false;
            },
            RemoveAccountCancel: function () {
                this.dialogRemoveAccount = false;
                this.removeId = '';
            },
           GetConnectedAccounts: function (){
            var self = this;
                  this.$http.post('/Accounts/GetAccounts', {statusCode: this.statusCode, accountId: this.accountId, accountName: this.accountName}).then(function (response) {
                 self.connectedAccounts = response.data.accounts;
             })
         }
      }
    }
</script>
