<template>
    <v-container>
        <v-row>
            <div class="col-md-12">
                <v-card elevation="2">
                    <v-card-title class="justify-center">
                        <h4 class="headerFont mr-5">
                            User Management
                        </h4>
                    </v-card-title>
                </v-card>
            </div>
        </v-row>
        <v-row>
            <div class="col-md-12">
                <v-card elevation="2" class="d-flex flex-row">
                    <div class="row d-flex">
                        <div class="col-md-2 mb-0 pb-0">
                            <v-select v-model="UserManagementData.LoginType"
                                      :items="LoginTypes"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Login Type"
                                      dense
                                      item-value="loginTypeId"
                                      class="mb-0 pb-0"
                                      item-text="loginTypeDescription"
                                      v-on:change="FilterUsers()"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-2 mb-0 pb-0">
                            <v-select v-model="UserManagementData.UserRoles"
                                      :items="UserRoles"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Role Type"
                                      dense
                                      multiple
                                      class="mb-0 pb-0"
                                      item-value="roleId"
                                      item-text="roleName"
                                      v-on:change="FilterUsers()"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-2 mb-0 pb-0">
                            <v-select v-model="UserManagementData.UserStatus"
                                      :items="UserStatus"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select User Status"
                                      dense
                                      class="mb-0 pb-0"
                                      item-value="statusId"
                                      item-text="userStatus"
                                      v-on:change="FilterUsers()"
                                      clearable>
                            </v-select>
                        </div>

                        <div class="col-md-2 mb-5 mb-0 pb-0">
                            <v-text-field v-model="SearchFilter"
                                          label="Search Users"
                                          dense
                                          class="mb-0 pb-0"
                                          hint="Search Users Here"
                                          clearable></v-text-field>
                        </div>
                        <div class="col-md-1 offset-lg-1 mt-1">

                            <v-btn color="primary" small v-on:click="ShowUserForm">
                                Add User(s)  <v-icon class="ml-1">mdi-account-plus</v-icon>
                            </v-btn>
                        </div>

                        <div class="col-md-1 mt-1">
                            <v-btn color="primary" outlined small v-on:click="BulkUpdateModal" :disabled="selectedItems.length == 0">
                                Bulk Actions  <v-icon class="ml-1">mdi-group</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-card>

                <div class="row">
                    <div class="col-md-12">
                        <v-card :loading="IsLoading">
                            <v-data-table :headers="headers"
                                          :items="ActiveUsers"
                                          :items-per-page="15"
                                          class="elevation-1"
                                          :search="SearchFilter"
                                          item-key="id"
                                          show-select
                                          dense
                                          v-model="selectedItems">
                                <template v-slot:item.actions="{ item }">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-on:click="EditUser(item)" v-bind="attrs" v-on="on">
                                                <v-icon>mdi-tooltip-edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Edit User</span>
                                    </v-tooltip>

                                    <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-on:click="RemoveUserConfirm(item.id)" v-bind="attrs" v-on="on">
                                                <v-icon>mdi-account-remove</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Remove User</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:item.loginType="{ item }">
                                    <v-chip small dense :color="RenderLoginTypeLabel(item.loginTypeId)" outlined>
                                        {{item.loginType}}
                                    </v-chip>
                                </template>
                                <template v-slot:item.roles="{ item }">
                                    <div class="p-0 m-0" v-if="item.roles">
                                        <v-chip v-for="(value, index) in item.roles.split(',')" v-bind:key="index" small dense color="black" outlined class="ml-1">
                                            {{value.split(':')[1]}}
                                        </v-chip>
                                    </div>

                                    <label v-else class="p-0 m-0">N/A</label>
                                </template>
                                <template v-slot:item.statusDescription="{ item }">
                                    <v-chip small dense :color="RenderUserStatusLabel(item.statusId)" outlined>
                                        {{item.statusDescription}}
                                    </v-chip>
                                </template>

                                <template v-slot:item.locationAccessNames="{ item }">
                                    <v-chip small dense color="black" outlined v-for="(value, index) in item.locationAccessNames" v-bind:key="index">
                                        {{value}}
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>
            </div>
        </v-row>
        <v-dialog v-model="DialogRemoveUser"
                  persistent
                  max-width="700">
            <v-card>
                <v-card-title class="headline grey--text">
                    Remove User
                </v-card-title>
                <v-card-text>This will Completely Remove This User</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined
                           Normal
                           @click="DialogRemoveUser = false; SelectedUserId = ''">
                        Cancel
                    </v-btn>
                    <v-btn color="green darken-1"
                           text
                           outlined
                           @click="RemoveUser">
                        Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog v-model="BulkUpdateDialog"
                  persistent
                  max-width="800">
            <v-card>
                <v-card-title class="grey--text align-center">
                    <h5> Bulk Update User(s) </h5>
                </v-card-title>
                <div class="container">

                <div class="row">
                    <div class="col-md-10 offset-lg-1 p-2">
                    <v-select v-model="BulkUpdate.LoginTypeId"
                              :items="LoginTypes"
                              :menu-props="{ maxHeight: '400' }"
                              label="Select Login Type"
                              dense
                              item-value="loginTypeId"
                              class="mb-0 pb-0"
                              item-text="loginTypeDescription"
                              clearable>
                    </v-select>
                        </div>
                </div>

                <div class="row">
                    <div class="col-md-10 offset-lg-1  p-2">
                        <v-select v-model="BulkUpdate.StatusId"
                                  :items="UserStatus"
                                  :menu-props="{ maxHeight: '400' }"
                                  label="Select User Status"
                                  dense
                                  class="mb-0 pb-0"
                                  item-value="statusId"
                                  item-text="userStatus"
                                  clearable>
                        </v-select>
                        </div>
                    </div>

                <div class="row">
                    <div class="col-md-10 offset-lg-1  p-2">
                        <v-text-field v-model="BulkUpdate.Password"
                                      label="Password"
                                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                      @click:append="showPass = !showPass"
                                      :type="showPass ? 'text' : 'password'"></v-text-field>
                          </div>
                       </div>
                    </div>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined
                               Normal
                               @click="BulkUpdateDialog = false;">
                            Cancel
                        </v-btn>
                        <v-btn color="green darken-1"
                               text
                               outlined
                               @click="BulkUpdateUsers">
                            Update
                        </v-btn>
                    </v-card-actions>
</v-card>
        </v-dialog>

        <v-dialog transition="dialog-top-transition"
                  max-width="1000" v-model="UserDialog">
            <template>
                <v-card>
                    <v-toolbar color="primary"
                               dark>{{FormAction}}</v-toolbar>

                    <UserForm :UserRoles="UserRoles"
                              :LoginTypes="LoginTypes"
                              :UserStatus="UserStatus"
                              :User="User"
                              v-on:CloseDialog="CloseModal"
                              v-on:CreateUserSuccess="CreateUserSuccessful"
                              v-on:CreateUserError="CreateUserError"
                              :valid="valid"
                              ref="userForm"
                              :Locations ="Locations"
                              > </UserForm>
                </v-card>
            </template>
        </v-dialog>
        <v-snackbar v-model="SuccessSnack"
                    color="success" rounded top right>
            {{ SuccessMessage }}
        </v-snackbar>

        <v-snackbar v-model="ErrorSnack"
                    color="error" rounded top right>
            {{ ErrorMessage }}

        </v-snackbar>
    </v-container>
</template>

<script>
    import  UserForm  from "../components/UserForm";
    export default {
        components: {
        UserForm
        },
        data: () => ({
            LoginTypes: [],
            FormAction: '',
            selectedItems: [],
            UserRoles: [],
            BulkUpdateDialog: false,
            SearchFilter: null,
            showPass: false,
            IsLoading: true,
            UserStatus: [],
            UserDialog: false,
            DialogRemoveUser:false,
            ActiveUsers: [],
            valid: true,
            UserFalid: false,
            EditMode: false,
            Locations:[],
            User: {

            },
            BulkUpdate: {
                LoginTypeId: '',
                UserRoles: [],
                StatusId: '',
                Password:''
            },
            SuccessSnack: false,
            ErrorSnack: false,
            ErrorMessage: 'Error Removing User',
            SuccessMessage: 'Successfuly Removed User',
            SelectedUserId: '',

            headers: [

                { text: 'First Name', value: 'firstName' },
                { text: 'Last Name', value: 'lastName' },
                { text: 'Roles', value: 'roles' },
                { text: 'Status', value: 'statusDescription' },
                { text: 'User Name', value: 'userName' },
                { text: 'Email', value: 'email' },
                { text: 'Login Type', value: 'loginType' },
                { text: 'Location Access', value: 'locationAccessNames', sortable: false },
                { text: '', value: 'actions', sortable: false }
            ],
            UserManagementData: {
                LocationType: '',
                UserRoles: [],
                UserStatus: '',
                SearchFilter: '',
                LoginType: '',
                Locations:[]
            }
        }),
        watch: {
            UserDialog: function () {

                if (!this.EditMode) {
                    this.User = {
                        FirstName: '',
                        LastName: '',
                        UserName: '',
                        Email: '',
                        Password: '',
                        LoginTypeId: '',
                        StatusId: 1000,
                        UserRoles: [],
                        Id: '',
                        Action: 'Add User',
                        LocationAccessIds: [],
                        MultiFactorEnabled: false
                    }
                }
                else {
                    this.User.Action = "Edit User"
                }

                if (this.UserDialog == false) {
                    this.$refs.userForm.$refs.observer.reset()
                    this.EditMode = false;
                }
            }
        },
        mounted: function () {
            this.LoadUsersData();
            this.IsLoading = false;
        },
        methods: {
            LoadUsersData: function () {
                var self = this;
                this.$http.post('/UserManagement/DefaultData', {}).then(function (response) {
                    if (response.data.success) {
                        self.LoginTypes = response.data.loginTypes;
                        self.UserRoles = response.data.userRoles;
                        self.UserStatus = response.data.userStatus;
                        self.ActiveUsers = response.data.activeUsers;
                        self.Locations = response.data.internalLocations;
                    }
                })
            },
            CreateUserSuccessful: function () {
                this.FilterUsers();
                this.CloseModal();
                this.SuccessMessage = this.EditMode ? "Successfully Updated User" : "Successfully Created User";
                this.SuccessSnack = true;
            },
            CreateUserError: function () {
                this.ErrorMessage = "Error Completing Your Request"
                this.ErrorSnack = true
            },
            EditUser: function (user) {
                this.EditMode = true;
                this.FormAction = 'Edit User';
                this.User = {
                    FirstName: user.firstName,
                    LastName: user.lastName,
                    UserName: user.userName,
                    Email: user.email,
                    Password: '',
                    LoginTypeId: user.loginTypeId,
                    StatusId: user.statusId,
                    UserRoles: user.roleIds,
                    Id: user.id,
                    Action: 'Edit User',
                    LocationAccessIds: user.locationAccessIds,
                    MultiFactorEnabled: user.multiFactorEnabled
                }

                this.UserDialog = true;

            },
            BulkUpdateModal: function () {
                this.BulkUpdateDialog = true;
            },
            BulkUpdateUsers: function () {
                var self = this;
                this.$http.post('/UserManagement/BulkUpdate', {UserIds: this.selectedItems.map(x => x.id), UpdateData: this.BulkUpdate}).then(function (response) {
                    if (response.data.success) {
                        self.FilterUsers();
                        self.SuccessMessage = "Update Successful"
                        self.SuccessSnack = true
                        self.BulkUpdateDialog = false;

                        self.BulkUpdate = {
                            LoginTypeId: '',
                            UserRoles: [],
                            StatusId: '',
                            Password: ''
                        }
                    }
                })
            },
            CloseModal: function () {
                this.UserDialog = false;
            },
            RemoveUserConfirm: function (userId) {
                var self = this;
                self.SelectedUserId = userId;
                self.DialogRemoveUser = true;
            },
            ShowUserForm: function () {
               this.FormAction = 'Add New User'
               this.UserDialog = true
            },
            FilterUsers: function () {
                var self = this;
                self.IsLoading = true;
                this.$http.post('/UserManagement/FilterUsers', this.UserManagementData).then(function (response) {
                    if (response.data.success) {
                        self.ActiveUsers = response.data.activeUsers;
                        self.IsLoading = false;
                    }
                    else {
                        self.IsLoading = false;
                    }
                })
            },
            RenderUserStatusLabel: function (statusId) {
                var color = "success";

                if (statusId == 2000)
                    color = "red"
                if (statusId == 3000)
                    color = "info"

                return color;
            },
            RenderLoginTypeLabel: function (loginTypeId) {

                var color = "primary";

                if (loginTypeId == 2000)
                    color = "info"

                return color;
            },
            RemoveUser: function () {
                var self = this;
                this.SuccessMessage = "Successfully Removed User";
                this.$http.post('/UserManagement/RemoveUser', { UserId: this.SelectedUserId }).then(function (response) {
                    if (response.data.success) {
                        self.IsLoading = false;
                        var selectedUserIndex =  self.ActiveUsers.findIndex(x => x.id == self.SelectedUserId)
                        self.ActiveUsers.splice(selectedUserIndex, 1);
                        self.SelectedUserId = '';
                        self.DialogRemoveUser = false;
                        self.SuccessSnack = true;
                    }
                    else {
                        self.IsLoading = false;
                        self.SelectedUserId = '';
                        self.DialogRemoveUser = false;
                        self.ErrorSnack = true;
                    }
                })
            }

        }
    }

</script>