<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
<template>
<v-main>
  <div class="container">
<v-row class="fill-height" align-content="center" justify="center" v-show="isSyncing">
      <v-col class="text-center headerFont"  cols="12">
       <b> Syncing Your Account </b>
      </v-col>
      <v-col cols="6">
        <div class="text-center">
          <v-progress-circular
            :size="100"
            color="#455a64"
            indeterminate
            width = "7"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
<v-spacer>
</v-spacer>
    <v-row  justify="center" v-show="isSyncing">

      <div class="col-md-1 offset-1">

    <v-img src="../assets/BelinoLogo.png" height="100px" width="100px">
                    </v-img>
      </div>
         <div class="col-md-1 text-center">
           <i class="fas fa-plus fa-3x  mt-5" style="color:grey"> </i>
      </div>
  <div class="col-md-3">
                      <v-img  src="https://1000logos.net/wp-content/uploads/2019/05/Square-Logo.png" height="100px" width="300px">
                    </v-img>
  </div>
    </v-row>

    <v-row v-show="!isSyncing" align-content="center" justify="center">
        <v-expand-x-transition>
            <div class="col-md-10">
                <v-alert prominent
                         type="success">
                    <strong>Account Successfully Synced </strong>
                </v-alert>
            </div>
       </v-expand-x-transition>
    </v-row>
    <v-spacer>
        <v-row v-show="!isSyncing" align-content="center" justify="center">
            <v-btn class="ma-2"
                   outlined
                   color="indigo" to="/Accounts">
                Accounts Home
            </v-btn>


            <v-btn class="ma-2"
                   outlined
                   color="success" to="/">
                Dashboard
            </v-btn>

              <v-btn class="ma-2"
                   outlined
                   color="primary" v-on:click="GetSquareUrl">
                Add Another Account
            </v-btn>
        </v-row>
    </v-spacer>
  </div>
</v-main>
</template>

<script>
    export default {
        data() {
            return {
                authcode: '',
                isSyncing: true
            }
        },
        mounted() {
        this.SetUpAccount();
        },
        methods: {
            SetUpAccount: function () {
                var route = this.$route.query.code;
                var self = this;
                this.$http.post('/Accounts/AccountSetUp', { code: route }).then(function (response) {

                    if (response.data.Success) {
                        self.isSyncing = false;
                    }
                    else {
                        self.isSyncing = false
                    }
                })
            },
            GetSquareUrl: function() {
                  this.$http.get('/Accounts/AuthLink', []).then(function (response) {
                  window.location.href = response.data.authLink;
             })
            }
          }
        }
</script>
