
<template>
    <validation-observer ref="observer">
        <v-form @submit.prevent="submit" ref="form">
            <v-container>

                <v-alert dense
                         outlined
                         type="error"
                         v-model ="userTaken"
                         dismissible
                         >
                    <strong>Username Is Taken Please Select Another Username</strong>
                </v-alert>

                <v-row>
                    <v-col cols="12"
                           md="4">
                        <validation-provider v-slot="{ errors }" name="First Name" rules="required" v-if="User.LoginTypeId != 2000">
                            <v-text-field v-model="User.FirstName"
                                          label="First name"
                                          :error-messages="errors"
                                          required></v-text-field>
                        </validation-provider>
                    </v-col>

                    <v-col cols="12"
                           md="4">
                        <validation-provider v-slot="{ errors }" name="Last Name" rules="required" v-if="User.LoginTypeId != 2000">
                            <v-text-field v-model="User.LastName"
                                          label="Last name"
                                          :error-messages="errors"
                                          required></v-text-field>
                        </validation-provider>
                    </v-col>

                    <v-col cols="12"
                           md="4">
                        <validation-provider v-slot="{ errors }" name="Email" rules="required|email" v-if="User.LoginTypeId != 2000">
                            <v-text-field v-model="User.Email"
                                          label="E-mail"
                                          :error-messages="errors"
                                          required></v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12"
                           md="4">
                        <validation-provider v-slot="{ errors }" name="Login Type" rules="required">
                            <v-select v-model="User.LoginTypeId"
                                      :items="LoginTypes"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Login Type"
                                      dense
                                      item-value="loginTypeId"
                                      class="mb-0 pb-0"
                                      :error-messages="errors"
                                      item-text="loginTypeDescription"
                                      v-on:change="AdjustLoginType()"
                                      required>
                            </v-select>
                        </validation-provider>
                    </v-col>

                    <v-col cols="12"
                           md="4">

                        <validation-provider v-slot="{ errors }" name="Role" rules="required">
                            <v-select v-model="User.UserRoles"
                                      :items="Roles"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Role Type"
                                      dense
                                      multiple
                                      :error-messages="errors"
                                      class="mb-0 pb-0"
                                      item-value="roleId"
                                      item-text="roleName"
                                      required>
                            </v-select>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12"
                           md="4">
                        <validation-provider v-slot="{ errors }" name="Status" rules="required">
                            <v-select v-model="User.StatusId"
                                      :items="UserStatus"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select User Status"
                                      dense
                                      :error-messages="errors"
                                      class="mb-0 pb-0"
                                      item-value="statusId"
                                      item-text="userStatus"
                                      required>
                            </v-select>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                           md="4" v-if="User.LoginTypeId == 2000">
                        <validation-provider v-slot="{ errors }" name="Location Access" rules="required">
                            <v-select v-model="User.LocationAccessIds"
                                      :items="Locations"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Location(s) Access"
                                      dense
                                      :error-messages="errors"
                                      class="mt-5 pb-0"
                                      item-value="locationId"
                                      item-text="locationName"
                                      multiple
                                      required>
                            </v-select>
                        </validation-provider>
                    </v-col>


                    <v-col cols="12"
                           md="3">
                        <validation-provider v-slot="{ errors }" name="Username" rules="required|min:5">
                            <v-text-field v-model="User.UserName"
                                          label="Username"
                                          :error-messages="errors"
                                          required></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12"
                           md="3">
                        <div v-if="User.Id == ''">
                            <validation-provider v-slot="{ errors}" name="Password" rules="validpassword|required">
                                <v-text-field v-model="User.Password"
                                              label="Password"
                                              required
                                              :error-messages="errors"
                                              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                              @click:append="showPass = !showPass"
                                              :type="showPass ? 'text' : 'password'"></v-text-field>
                            </validation-provider>
                        </div>

                        <div v-if="User.Id != ''">
                            <validation-provider v-slot="{ errors}" name="Password" rules="validpassword">
                                <v-text-field v-model="User.Password"
                                              label="Password"
                                              :error-messages="errors"
                                              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                              @click:append="showPass = !showPass"
                                              :type="showPass ? 'text' : 'password'"></v-text-field>
                            </validation-provider>
                        </div>
                    </v-col>

                    <v-col cols="12"
                           md="3"  v-if="User.LoginTypeId != 2000">

                        <v-checkbox v-model="User.MultiFactorEnabled"
                                    label="Require Mobile Authentication"></v-checkbox>

                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions class="justify-end">
                <v-btn color="primary" outlined
                       v-on:click="$emit('CloseDialog')" typeof="button">Close</v-btn>
                <v-btn color="primary"
                       v-on:click="AddNewUser" :loading="CreatingUser" :disabled="CreatingUser">
                 {{User.Action}}
                </v-btn>

            </v-card-actions>
        </v-form>

        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <v-alert
                             dense
                             elevation="2">





                        <v-list dense>
                            <v-list-item-group>
                                <v-list-item v-for="(item, index) in ValidationSummaryPanel" v-bind:key="index" class="p-0 m-0" dense>
                                    <v-list-item-icon>
                                        <v-icon v-text="item.Valid ? 'mdi-checkbox-marked-circle-outline' : 'mdi-minus-circle'" :color="item.Valid ? 'success' : 'error'" medium class="pt-0 pb-0 mb-0 mt-0"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="pt-0 pb-0 mb-0 mt-0">
                                        <v-list-item-title v-text="item.Text"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-alert>
                </div>
            </div>
        </div>

        </validation-observer>


</template>
<script>
import { ValidationProvider, ValidationObserver, Validator } from 'vee-validate';

    /* eslint-disable no-useless-escape , vue/no-unused-components*/
    //Exporting this so it can be used in other components

    Validator.extend("validpassword",  {

        getMessage: "Password Is Invaild",

        validate: value => new Promise(resolve => {
            var hasLowerCase = false;
            var hasUpperCase = false;
            var hasSpecialCharacter = false;
            var hasNumber = false;
            var hasLength = false;

            var result = false;

                hasLowerCase = IsValidExpression(value, "^(?=.*[a-z])");

                hasUpperCase = IsValidExpression(value, "^(?=.*[A-Z])");
                //customMessage = 'Must Contain 1 Uppercase Character'

                hasSpecialCharacter = IsValidExpression(value, "^(?=.*[!@#$%^&*])");
                //customMessage = 'Must Contain 1 Special Character'

                hasNumber = IsValidExpression(value, "^(?=.*[0-9])");
                //customMessage = 'Must Contain 1 Numeric Character'

                hasLength = IsValidExpression(value, "(?=.{8,})");

                result = hasLowerCase && hasUpperCase && hasSpecialCharacter && hasNumber && hasLength;
            resolve({
                valid: result
            });
        })
    });

    export default {
        name: 'UserForm',
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            UserRoles: {
                type: Array,
            },
            LoginTypes: {
                type: Array,
            },
            UserStatus: {
                type: Array,
            },
            User: {
                type: Object
            },
            valid: {
                type: Boolean
            },
            Locations: {
                type: Array
            }
        },
        data: () => ({
            showPass: false,
            Roles: [],
            userTaken: false,
            CreatingUser: false,
            ValidationSummaryPanel: [{
                Text: 'Password Must Contain 1 Lowercase Character',
                Valid: false,
                RegEx: "^(?=.*[a-z])"
            },
            {
                Text: 'Password Must Contain 1 Uppercase Character',
                Valid: false,
                RegEx: "^(?=.*[A-Z])"
            },
            {
                Text: 'Password Must Contain 1 Special Character',
                Valid: false,
                RegEx: "^(?=.*[!@#$%^&*])"
            },
            {
                Text: 'Password Must Contain 1 Numeric Character',
                Valid: false,
                RegEx: "^(?=.*[0-9])"
            },
            {
                Text: 'Password Must Be At Least 8 Characters Long',
                Valid: false,
                RegEx: "(?=.{8,})"
            },
            ]
        }),
        watch: {
            User: {
                handler() {
                    for (var i = 0; i < this.ValidationSummaryPanel.length; i++) {
                        if (this.ValidationSummaryPanel[i].CharacterCount) {
                            this.ValidationSummaryPanel[i].Valid = this.User.PassWord.length <= this.ValidationSummaryPanel[i].Amount
                        }
                        else {
                            this.ValidationSummaryPanel[i].Valid = IsValidExpression(this.User.Password, this.ValidationSummaryPanel[i].RegEx) && this.User.Password != ''
                        }
                    }
                },
                deep: true
            }
        },
            mounted: function () {
                this.AdjustLoginType();
            },

            methods: {
                async AddNewUser() {

                    this.userTaken = false;

                    var self = this;
                    const valid = await this.$refs.observer.validate();

                    if (valid) {
                        this.CreatingUser = true;
                        this.$http.post('/UserManagement/AddOrUpdateUser', { User: this.User }).then(function (response) {
                            if (response.data.success) {
                                self.CreatingUser = false;
                                self.$emit('CreateUserSuccess')
                            }
                            else {
                                self.CreatingUser = false;

                                if (response.data.userExists) {
                                    self.userTaken = true;
                                }
                                else {
                                    self.$emit('CreateUserError')
                                    self.$refs.observer.reset()
                                }
                            }
                        })

                    }
                },

                IsItemValid: function (regex) {
                    return IsValidExpression(this.User.PassWord, regex)
                },

                AdjustLoginType: function () {
                    if (this.User.LoginTypeId == 2000) {
                        this.Roles = this.UserRoles.filter(x => x.isGroup == true)
                        this.User.MultiFactorEnabled = false;
                    }
                    else {
                        this.Roles = this.UserRoles
                    }
                }
            }
        }

    function IsValidExpression (pw, regex) {
        var regexExpression = new RegExp(regex);
        return regexExpression.test(pw)
    }
</script>