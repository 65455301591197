<style>
.v-application--wrap {
  background-color: #e1e1e1;
}
</style>

<style scoped>
    .v-progress-circular {
        margin: 1rem;
    }
</style>

<template>
    <v-container  class="Dashboard" style="background-color: #e1e1e1">
        <div class="row pb-0 d-flex justify-center">
            <div class="col-md-12">
                <v-card  title="Sales Summary" flat>
                    <div class="row">
                        <div class="col-md-3 mt-5" style="padding-bottom: 0">
                            <v-select v-model="selectedAccounts"
                                      :items="connectedAccounts"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Active Account(s)"
                                      :loading="accountsLoading"
                                      multiple
                                      dense
                                      class="mt-1"
                                      item-text="friendlyName"
                                      item-value="accountId">
                                <!--v-on:change="GetDashboardSales">-->

                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0" dense>
                                        <span>{{ item.friendlyName }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text caption">
                                        (+{{ selectedAccounts.length - 1 }} others)
                                    </span>
                                </template>
                            </v-select>
                        </div>
                        <div class="col-md-5 pb-0 offset-1" style="padding-bottom: 0">
                            <v-radio-group v-model="timeFrame"
                                           row
                                           v-on:change="GetDashboardSales(false, true)">
                                <v-radio label="Today" value="1000"></v-radio>
                                <v-radio label="Yesterday" value="2000"></v-radio>
                                <v-radio label="Week" value="3000"></v-radio>
                                <v-radio label="Year" value="4000"></v-radio>

                                <!--<v-checkbox v-model="includePrevSales"
                                            label="Compare Sales"
                                            class="ml-5"
                                            color="indigo darken-3"></v-checkbox>-->

                                <v-tooltip top right color="primary" class="ml-5">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" v-on:click="GetDashboardSales(false, true)"
                                               v-on="on" class="mt-3">
                                            <v-icon>mdi-refresh</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh Sales</span>
                                </v-tooltip>
                            </v-radio-group>
                        </div>

                        <div class="col-md-3 pb-0 mt-3" style="padding-bottom: 0">
                            <v-menu v-model="dateMenu"
                                    ref="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    :return-value.sync="dates"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateRangeText"
                                                  label="Custom Date(s)"
                                                  prepend-icon="mdi-calendar"
                                                  v-bind="attrs"
                                                  clearable
                                                  v-on="on"
                                                  readonly></v-text-field>
                                </template>
                                <v-date-picker v-model="dates" no-title range>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dateMenu = false"> Cancel </v-btn>
                                    <v-btn text color="primary" @click="GetDashboardSales(true)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </div>
                    </div>
                </v-card>
            </div>
        </div>
                <div class="row  d-flex" v-if="salesChartHasData" eager>
                    <div :class=" includePrevSales == true ? 'col-md-6' : 'col-md-12' " >
                        <v-expand-x-transition>
                            <v-card   class="justify-center"  flat height="100%">
                                <v-card-title class="justify-center" style="color: grey">
                                    Selected Year
                                    <v-chip class="ma-2"
                                            color="primary"
                                            outlined>
                                        <v-icon left>
                                            mdi-calendar
                                        </v-icon>
                                        ({{reportStart}} - {{reportEnd}})
                                    </v-chip>
                                </v-card-title>

                                <div class="row d-flex justify-center fill-height">
                                    <div class="col-md-4 offset-md-2 pa-3 py-4 primary--text justify-center">
                                        <v-badge offset-x="3"
                                                 offset-y="3"
                                                 bordered
                                                 :color="
                        salesStatsText(currentReport.stats).badgeColor
                      "
                                                 :content="currentReport.stats.grossSalesPercentage">
                                            <h5 class="text-truncate text-uppercase grey--text">
                                                Gross Sales
                                            </h5>
                                        </v-badge>
                                        <h3 class="justify-center grey--text">
                                            {{ currentReport.grossSales }}
                                        </h3>
                                    </div>
                                    <div class="col-md-4 pa-3 py-4 grey--text justify-center">
                                        <v-badge offset-x="3"
                                                 offset-y="3"
                                                 bordered
                                                 :color="
                        salesStatsText(currentReport.stats).badgeColor
                      "
                                                 :content="currentReport.stats.netSalesPercentage">
                                            <h5 class="text-truncate text-uppercase justify-center">Net Sales</h5>
                                        </v-badge>
                                        <h3 class="justify-center">{{ currentReport.netSales }}</h3>
                                    </div>
                                </div>
                            </v-card>
                        </v-expand-x-transition>
                    </div>
                    <div class="col-md-6" height="100%" v-if="includePrevSales">
                        <v-expand-x-transition>
                            <v-card flat  height="100%">
                                <v-card-title class="justify-center" style="color: grey">Previous Year</v-card-title>
                                <v-row class="justify-center"
                                       align-content="center"
                                       justify="center">
                                    <div class="col-md-4 offset-md-2 pa-3 py-4 grey--text justify-center">
                                        <h5 class="text-truncate text-uppercase">Gross Sales</h5>
                                        <h3 v-if="previousReport">{{ previousReport.grossSales }}</h3>
                                    </div>
                                    <div class="col-md-4 pa-3 py-4 grey--text justify-center">
                                        <h5 class="text-truncate text-uppercase">Net Sales</h5>
                                        <h3 v-if="previousReport">{{ previousReport.netSales }}</h3>
                                    </div>
                                </v-row>
                            </v-card>
                        </v-expand-x-transition>
                    </div>
                </div>
            <div class="container">
            <div class="row" eager>
                <div class="col-md-12">
                    <v-row align-content="center" justify="center" v-if="!currentChartHasData && !previousChartHasData && !chartsLoading" >
                        <div class="col-md-10">

                            <v-alert icon="mdi-chart-box"
                                     color="#2A3B4D"

                                     dark
                                    >
                                <strong>No Sales Data Available</strong>
                            </v-alert>
                        </div>
                    </v-row>


                    <v-card title="Sales Summary" v-if="chartsLoading || currentChartHasData " flat>
                        <v-row class="fill-height"
                               align-content="center"
                               justify="center">
                            <v-col class="text-subtitle-1 text-center"
                                   cols="12"
                                   v-if="!currentChartHasData && !previousChartHasData && chartsLoading">
                                Loading Report
                            </v-col>


                            <v-col cols="6" class="text-center">
                                <v-progress-circular :size="50"
                                                     color="primary"
                                                     indeterminate
                                                     v-if="chartsLoading"></v-progress-circular>
                            </v-col>
                        </v-row>

                        <v-overlay :absolute="true"
                                   :value="chartsLoading ">
                        </v-overlay>

                        <line-chart :chart-data="chartData" :responsive="true" v-if="currentChartHasData"></line-chart>
                    </v-card>
                </div>
          </div>

            <div class="row" eager>
                <div :class=" includePrevSales == true ? 'col-md-6' : 'col-md-12' " v-if="currentChartHasData && !chartsLoading">
                    <v-expand-x-transition>
                        <v-card flat class="justify-center">
                            <h5 class="text-truncate text-uppercase grey--text text-center mb-1 mt-1">
                                Selected State Sales
                            </h5>
                            <doughnut-chart :chart-data="stateData" :responsive="true"></doughnut-chart>
                        </v-card>
                    </v-expand-x-transition>
                </div>

                <div class="col-md-6" v-if="previousChartHasData && !chartsLoading && includePrevSales">
                    <v-expand-x-transition>
                        <v-card class="justify-center" flat>
                            <h5 class="text-truncate text-uppercase grey--text text-center mb-1 mt-1">
                                Previous State Sales
                            </h5>
                            <doughnut-chart :chart-data="previousStateData" :responsive="true"></doughnut-chart>
                        </v-card>
                    </v-expand-x-transition>
                </div>

            </div>
           </div>



    </v-container>
</template>


<script>
    import LineChart from "@/components/LineChart";
    import DoughnutChart from "@/components/DoughnutChart";


//import BarChart from '@/components/BarChart'

// @ is an alias to /src
export default {
  name: "Dashboard",
  components: {
      LineChart,
      DoughnutChart,
  },
  data() {
    return {
      chartType: 2000,
      connectedAccounts: [],
      selectedAccounts: [],
      includePrevSales: true,
      accountsLoading: true,
      chartsLoading: true,
      chartData: {},
      currentChartHasData: false,
      previousChartData: {},
      previousChartHasData: false,
      previousStateData: {},
      salesChartHasData: false,
      showNoData: false,
      dates: [],
      reportStart: '',
      reportEnd:'',
      locationStates:[],
      menu: false,
      dateMenu: false,
      timeFrame: "1000",
      currentReport: {
        grossSales: 0,
        netSales: 0,
        stats: {},
        },
      chartRenderValue: 0,
      chartRenderinterval: {},
      stateData: {},
      previousReport: {
        grossSales: 0,
        netSales: 0,
      },
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join("   ~ to ~   ");
    },
  },
  mounted: function () {
  var self = this;

  this.GetConnectedAccounts().then(function () {
       self.GetDashboardSales(false);
    });
  },
  methods: {
    GetConnectedAccounts: function () {
      var self = this;
      return this.$http
        .post("/Accounts/GetAccounts", {
            StatusCode: this.statusCode,
            AccountId: this.accountId,
            AccountName: this.accountName,
        })
          .then(function (response) {
             self.connectedAccounts = response.data.accounts;
             self.selectedAccounts = response.data.accounts.map((a) => a.accountId);
             self.locationStates = response.data.locationStates;
             self.accountsLoading = false;
        });
      },
      salesStatsText: function (stats) {

          var SalesConcept = {
        badgeColor: "success",
        icon: "mdi-arrow-up-thick",
        iconText: "Up ",
      };

        if (!stats.grossSalesIncrease || !stats.netSalesIncrease ) {
          (SalesConcept.badgeColor = "error"),
          (SalesConcept.icon = "mdi-arrow-down-thick"),
          (SalesConcept.iconText = "Down ");
        }

        if (stats.isEvenGross || stats.isEvenNet) {
            (SalesConcept.badgeColor = "primary"),
            (SalesConcept.icon = "mdi-minus"),
            (SalesConcept.iconText = "No Change ");
        }

      return SalesConcept;
    },
      GetDashboardSales: function (cancelRadioValue, clearDates) {

          this.$refs.menu.save(this.dates);

          if (clearDates) this.dates = [];

          if (cancelRadioValue) this.timeFrame = 5000;

          var self = this;
          self.chartsLoading = true;
          self.showNoData = false;

          this.$http
              .post("/Accounts/Dashboard", {
                  AccountIds: this.selectedAccounts,
                  ChartType: this.timeFrame,
                  DateRange: this.dates,
                  LocationStates: this.locationStates,
                  IncludePrevious: this.includePrevSales
              })
              .then(function (response) {

                  if (response.data.startDate)
                      self.reportStart = response.data.startDate
                  if (response.data.endDate)
                      self.reportEnd = response.data.endDate


                  self.showNoData = !response.data.chartHasData;
                  self.salesChartHasData = response.data.chartHasData;
                  self.currentReport.grossSales = response.data.reports[0].grossSales;
                  self.currentReport.stats = response.data.reports[0].stats;
                  self.currentReport.netSales = response.data.reports[0].netSales;

                  if (self.includePrevSales) {
                      self.previousReport.grossSales = response.data.reports[1].grossSales;
                      self.previousReport.netSales = response.data.reports[1].netSales;
                      self.previousChartHasData = response.data.reports[1].grossSales != '$0.00';
                  }
                  self.chartType = response.data.chartType;
                  self.currentChartHasData = response.data.reports[0].grossSales != '$0.00';

                  if (response.data.reports[0].sales.length != 0 && !response.data.reports[1].sales)
                  {
                      for (var i = 0; i < response.data.reports[0].sales.length; i++) {
                          response.data.reports[1].sales.push(0);
                      }
                  }

                  if (response.data.chartHasData) {
                      self.showNoData = false;
                      var currentColorsArray = [];
                      var previousColorsArray = [];

                      for (var p = 0; p < self.locationStates.length; p++) {
                          currentColorsArray.push(getRandomColor());
                          previousColorsArray.push(getRandomColor());
                      }

                      self.stateData = {
                          labels: response.data.reports[0].stateTotals.labels,
                          datasets: [
                              {
                                  label: "Current State Data",
                                  data: response.data.reports[0].stateTotals.stateAmount,
                                  backgroundColor: currentColorsArray,
                              },
                          ],
                      };

                     
                          self.previousStateData = {
                              labels: response.data.reports[0].stateTotals.labels,
                              datasets: [
                                  {
                                      label: "Current State Data",
                                      data: response.data.reports[1].stateTotals.stateAmount,
                                      backgroundColor: previousColorsArray,
                                  },
                              ],
                          }
                      

                      var previousChartDataColor = getRandomColor();
                      var getCurrentChartDataColor = getRandomColor();


                      var dataSets = [
                            {
                              label: "Selected Year",
                              backgroundColor: getCurrentChartDataColor,
                              data: response.data.reports[0].sales,
                              fill: false,
                              borderColor: "#64B5F6",
                              pointBackgroundColor: "#1976D2",
                              pointBorderColor: "#64B5F6",
                          }
                      ]

                    
                            dataSets.push({
                                label: "Previous Year",
                                backgroundColor: previousChartDataColor,
                                data: response.data.reports[1].sales,
                                fill: false,
                                borderColor: "#FFAB40",
                                pointBackgroundColor: "#FF5722",
                                pointBorderColor: "#FFAB40",
                            })
                      
                      self.chartData = {
                          labels: response.data.reports[0].chartLabels,
                          datasets: dataSets
                      };
                  }

          else{
              self.currentReport = {
                     grossSales: 0,
                     netSales: 0,
                     stats: {},
                     },
                     self.previousReport =  {
                     grossSales: 0,
                     netSales: 0
              }
                      self.showNoData = true;
                  }

                       self.chartsLoading = false;
                     });
    },
  },
    };


    function getRandomColor() {
        var color = 'rgba(';
        for (var i = 0; i < 3; i++) {
            color += Math.floor(Math.random() * 255) + ',';
        }
        color +=  ' 0.6' + ')'; // add the transparency
        return color;
    }

</script>
