


<style>
    .e-grid .e-rowcell {
        border-style: solid;
        border-width: 1px 0 0 0;
        display: table-cell;
        font-size: 13px !important;
        line-height: 15px;
        overflow: hidden;
        padding: 8px 10px !important;
        vertical-align: middle;
        height: 20px !important;
        white-space: nowrap;
        width: auto;
    }

    .e-grid .e-headercell, .e-grid .e-detailheadercell {
        border-style: solid;
        border-width: 0;
        font-size: 12px;
        font-weight: 500;
        height: 32px;
        overflow: hidden;
        padding: 0 21px 0;
        position: relative;
        text-align: left;
    }

    .e-pager .e-currentitem, .e-pager .e-currentitem:hover {
        background: #3f51b5;
        color: #fff;
        opacity: 1;
    }

    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 2px;
    }

    input.e-input::selection, textarea.e-input::selection, .e-input-group input.e-input::selection, .e-input-group.e-control-wrapper input.e-input::selection, .e-float-input input::selection, .e-float-input.e-control-wrapper input::selection, .e-input-group textarea.e-input::selection, .e-input-group.e-control-wrapper textarea.e-input::selection, .e-float-input textarea::selection, .e-float-input.e-control-wrapper textarea::selection {
        background: #3f51b5 !important;
        color: #fff !important;
    }

    .e-checkbox-wrapper:hover .e-frame.e-check, .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
        background-color: rgb(0 0 0 / 87%);
        border-color: transparent;
        color: #fff;
    }

    .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
        background-color: #3f51b5;
        border-color: transparent;
        color: #fff;
    }

    .e-toolbar-right, .e-headercontent {
        padding-right: 10px;
    }

    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
        background: #3f51b5;
    }
</style>

<template>
    <v-container>
        <v-row class="mt-0">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <v-card :loading="isLoading">
                            <v-card-title class="justify-center">
                                <h4 class="headerFont mr-5 mb-1 text-center">
                                    Location Management            <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon small color="grey" class="ml-2" v-on="on" v-bind="attrs" @click="WareHouseManageLocations = true">
                                                <v-icon>mdi-warehouse</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Manage Warehouse(s)</span>
                                    </v-tooltip>
                                </h4>
                            </v-card-title>

                            <div class="row">
                                <div class="col-md-12">
                                    <ejs-grid ref="grid" :toolbar='toolbar' :cellSave="UpdateRecords" :editSettings="editSettings" :toolbarClick='assignWarehouse' :dataSource="belinoInventoryLocations" height=600 :selectionSettings='selectionOptions' :pageSettings='pageSettings' :allowFiltering='true' :filterSettings='filterOptions' :enableInfiniteScrolling='true' :allowSorting='true'>
                                        <e-columns>
                                            <e-column field='al.al.name' headerText='Location Name' :allowEditing='true' :validationRules="RequiredRules" :width=400></e-column>
                                            <e-column field='al.al.address.address_line_1' headerText='Address' :allowEditing='true' :validationRules="RequiredRules"></e-column>
                                            <e-column field='al.al.address.locality' headerText='City' :allowEditing='true' :validationRules="RequiredRules"></e-column>
                                            <e-column field='al.al.address.administrative_district_level_1' headerText='State' :allowEditing='true' :validationRules="RequiredRules"></e-column>
                                            <e-column field='al.al.address.postal_code' headerText='Zip' :allowEditing='true' :validationRules="RequiredRules"></e-column>
                                            <e-column field="locationName" headerText='Square Account' :allowEditing='false'></e-column>
                                            <e-column field='warehouse' headerText='Warehouse Location' :allowEditing='false'></e-column>
                                            <e-column type='checkbox' width='50'></e-column>
                                        </e-columns>
                                    </ejs-grid>
                                </div>
                            </div>

                        </v-card>
                    </div>
                </div>
            </div>
        </v-row>


        <v-dialog v-model="WareHouseManageLocations"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition">

            <v-card>


                <v-toolbar dark
                           color="primary">

                    <v-toolbar-title>Manage Warehouse Locations</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <div class="flex-grow-1"></div>

                        <v-btn icon small class="ml-5" v-on:click="showCreateHouseModal(null)">
                            <v-icon>mdi-tray-plus</v-icon>
                        </v-btn>
                        <v-btn icon
                               dark
                               @click="WareHouseManageLocations = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>

                </v-toolbar>

                <div class="container">
                    <v-data-table :headers="WareHouseLocationHeaders"
                                  :items="wareHouseLocations"
                                  class="elevation-1 mt-5" dense>
                        <template v-slot:item.locationName="{ item }">
                            <v-chip color="primary"
                                    outlined small>
                                {{ item.locationName }}
                            </v-chip>
                        </template>
                        <template v-slot:item.assignedLocationsCount="{ item }">
                            <v-chip color="warning"
                                    outlined small>
                                {{ GetInventoryCount(item.locationId)}}
                            </v-chip>
                        </template>
                        <template v-slot:item.actions="{ item }">

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon
                                           color="primary" v-on:click="showEditWareHouseModal(item.locationId)" v-bind="attrs" v-on="on">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit Warehouse</span>
                            </v-tooltip>

                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon
                                           color="grey" v-on:click="ShowDeleteConfirm(item.locationId)" v-bind="attrs" v-on="on" :disabled="GetInventoryCount(item.locationId) != 0">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete Warehouse</span>
                            </v-tooltip>
                        </template>

                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>


        <v-dialog v-model="WarehouseDialog" max-width="700" eager :loading="EditingOrAdding">
            <v-card>
                <v-container>
                    <v-card-title>
                        {{WarehouseAction}} Warehouse
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-form ref="form"
                            v-model="AddNewItemValid"
                            lazy-validation>
                        <div class="row mt-1">
                            <div class="col-md-6">
                                <v-text-field v-model="editedItem.locationName"
                                              label="Name"
                                              :rules="[rules.required]"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable></v-text-field>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-12">
                                <v-text-field v-model="editedItem.locationAddress"
                                              label="Address"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable></v-text-field>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-4">
                                <v-text-field v-model="editedItem.locationCity"
                                              label="City"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable></v-text-field>
                            </div>
                            <div class="col-md-4">
                                <v-text-field v-model="editedItem.locationState"
                                              label="State"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable
                                              maxlength="2"></v-text-field>
                            </div>
                            <div class="col-md-4">
                                <v-text-field v-model="editedItem.locationZip"
                                              label="Zip"
                                              dense
                                              class="mb-0 pb-0  mt-1"
                                              clearable></v-text-field>
                            </div>
                        </div>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"
                               @click="WarehouseDialog = false"
                               class="white--text" outlined>
                            Cancel
                        </v-btn>

                        <v-btn color="primary"
                               @click="CreateOrEditWareHouse"
                               class="white--text" :disabled="!AddNewItemValid">
                            {{WarehouseAction}}
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>

        </v-dialog>

        <v-dialog v-model="DeleteModal"
                  max-width="500">
            <v-card>
                <v-card-title class="headline">
                    Delete Item(s)
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                    Are You Sure You Want To Delete These Item(s)?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1"
                           @click="DeleteModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="DeleteWareHouseItem">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog v-model="BulkEditModal"
                  max-width="400">
            <v-card :loading="isLoading">
                <v-card-title class="headline">
                    Assign Inventory Location(s)
                </v-card-title>
                <v-spacer></v-spacer>

                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <v-select v-model="SelectedLocationId"
                                      :items="wareHouseLocations"
                                      :menu-props="{ maxHeight: '400' }"
                                      label="Select Warehouse Location"
                                      dense
                                      item-value="locationId"
                                      class="mb-0 pb-0 mr-4 mt-1"
                                      item-text="locationName"
                                      clearable>
                            </v-select>
                        </div>
                    </div>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary"
                           @click="BulkEditModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                    <v-btn color="primary"
                           @click="BulkUpdateItems" outlined :disabled="SelectedLocationId == '' || !SelectedLocationId">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="NoItemModal" max-width="400">
            <v-card :loading="isLoading">
                <v-card-title>
                    You Must Select At Least One Item
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error"
                           @click="NoItemModal = false"
                           class="white--text">
                        Cancel
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="SuccessSnack"
                    color="success" rounded top right>
            {{ SuccessMessage }}
        </v-snackbar>
        <v-snackbar v-model="ErrorSnack"
                    color="error" rounded top right>
            {{ ErrorMessage }}
        </v-snackbar>
    </v-container>


</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import Vue from 'vue';
    import { GridPlugin, Page, Sort, DataResult, InfiniteScroll, Filter, Toolbar, Edit } from '@syncfusion/ej2-vue-grids';

    Vue.use(GridPlugin);

    // eslint-disable-next-line no-unused-vars
    export default {
        provide: {
            grid: [Page, Sort, InfiniteScroll, Filter, Toolbar, Edit]
        },
        data: () => ({
            BulkEditModal: false,
            WareHouseManageLocations: false,
            WareHouseLocationHeaders: [
                { text: 'Warehouse Name', value: 'locationName' },
                { text: 'Address', value: 'locationAddress' },
                { text: 'City', value: 'locationCity' },
                { text: 'State', value: 'locationState' },
                { text: 'Zip', value: 'locationZip' },
                { text: '# Inventory Locations Assigned Amount', value: 'assignedLocationsCount' },
                { text: '', value: 'actions', sortable: false },

            ],
            rules: {
                required: value => !!value || 'Required.'
            },
            WarehouseDialog: false,
            editedItem: {
                locationName: '',
                locationAddress: '',
                locationCity: '',
                locationZip: '',
                locationState: '',
                locationId: ''
            },
            DeleteModal: false,
            DeleteId: '',
            EditingOrAdding: false,
            AddNewItemValid: true,
            WarehouseAction: '',
            AddEditDialog: false,
            isLoading: true,
            NoItemModal: false,
            SuccessSnack: false,
            ErrorSnack: false,
            wareHouseLocations: [],
            SelectedLocationId: '',
            ErrorMessage: 'Error Assigning Inventory Location(s)',
            SuccessMessage: 'Successfuly Assigned Inventory Location(s)',
            belinoInventoryLocations: [],
            connectedAccounts: [],
            accountStates: [],
            accountCities: [],
            editSettings: { allowEditing: true, allowAdding: false, allowDeleting: false, newRowPosition: 'Bottom', mode: 'Batch' },
            RequiredRules: { required: true },
            toolbar: [{ text: 'Update', align: 'right', id: 'editItems' }],
            sortOptions: { columns: [] },
            selectionOptions: { type: 'Multiple', checkboxMode: 'ResetOnRowClick' },
            filterOptions: { type: "FilterBar", mode: 'Immediate', operator: 'Contains', matchCase: false, enableCaseSensitivity: false },
            pageSettings: { pageSize: 25 },

        }),

        created() {
            this.LoadLocationData(true)
        },
        methods: {
            GetInventoryCount: function (locationId) {
                return this.belinoInventoryLocations.filter(x => (x.li) && x.li.locationId == locationId).length;
            },
            LoadLocationData: function (showLoader) {
                var self = this;
                self.isLoading = showLoader;
                this.$http.post('/Inventory/LocationManagementData', {}).then(function (response) {
                    if (response.data.success) {
                        self.wareHouseLocations = response.data.inventoryLocations;
                        self.connectedAccounts = response.data.merchantAccounts;
                        self.belinoInventoryLocations = response.data.joinLocations.map(s => ({
                            al: s.al, li: s.li, locationName: self.getWareHouseLocation(s.al.al.merchant_id),
                            warehouse: s.li == null ? "Not Assigned" : (s.li.locationName == '' ? 'Not Assigned' : s.li.locationName)
                        }));

                        self.isLoading = false;
                    }
                    else {
                        self.isLoading = false;
                    }
                })
            },
            ShowDeleteConfirm: function (locationId) {
                this.DeleteId = locationId;
                this.DeleteModal = true;
            },
            DeleteWareHouseItem: function () {
                var self = this;
                self.EditingOrAdding = true;
                this.$http.post('/Inventory/DeleteWareHouseLocaiton', { LocationId: this.DeleteId }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = 'Successfully Removed Warehouse'
                        self.SuccessSnack = true;
                        self.LoadLocationData(false);
                        self.DeleteModal = false;
                        self.DeleteId = '';
                    }
                    else {
                        self.ErrorMessage = 'Error Removing Warehouse'
                        self.ErrorSnack = true;
                        self.DeleteModal = false;
                        self.DeleteId = '';
                    }
                })
            },
            CreateOrEditWareHouse: function () {
                var self = this;
                self.EditingOrAdding = true;
                this.$http.post('/Inventory/AddWareHouseLocaiton', { WarehouseLocation: this.editedItem }).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = 'Successfully Added/Updated Warehouse'
                        self.SuccessSnack = true;
                        self.EditingOrAdding = false;
                        self.editedItem = {};
                        self.LoadLocationData(false);
                        self.WarehouseDialog = false;
                    }
                    else {
                        self.SuccessMessage = 'Error Adding/Updating Warehouse'
                        self.EditingOrAdding = false;
                        self.isLoading = false;
                        self.ErrorSnack = true;
                    }
                })
            },

            showEditWareHouseModal: function (locationId) {
                var getSelectedIndex = this.wareHouseLocations.filter(x => x.locationId == locationId)[0];
                this.editedItem = {
                    locationName: getSelectedIndex.locationName,
                    locationAddress: getSelectedIndex.locationAddress,
                    locationCity: getSelectedIndex.locationCity,
                    locationZip: getSelectedIndex.locationZip,
                    locationState: getSelectedIndex.locationState,
                    locationId: getSelectedIndex.locationId,
                    locationType: 3
                }
                this.WarehouseAction = 'Update'
                this.WarehouseDialog = true;
            },
            showCreateHouseModal: function (locationId) {
                var getSelectedIndex = this.wareHouseLocations.filter(x => x.locationId == locationId)[0];
                this.editedItem = {
                    locationName: '',
                    locationAddress: '',
                    locationCity: '',
                    locationZip: '',
                    locationState: '',
                    locationId: null,
                    locationType: 3
                }
                this.WarehouseAction = 'Create'
                this.WarehouseDialog = true;
            },
            getWareHouseLocation(merchantId) {
                var getlocationMerchantName = this.connectedAccounts.filter(x => x.merchantId == merchantId)[0].friendlyName;
                return getlocationMerchantName.split('-')[1].trim();
            },
            BulkUpdateItems: function () {
                var self = this;
                self.BulkEditModal = false
                this.isLoading = true;
                var selectedItems = this.$refs.grid.getSelectedRecords();

                var newItems = selectedItems.filter(x => x.li == null);
                var existingItems = selectedItems.filter(x => x.li != null);

                var data = {
                    WareHouseId: self.SelectedLocationId,
                    ExistingInventoryLocations: existingItems.length != 0 ? existingItems.map(x => x.li.retailLocationId) : null,
                    NewInventoryLocations: newItems.length != 0 ? newItems.map(x => x.al.al.id) : null
                }

                this.$http.post('/Inventory/AssignWareHouseLocations', data).then(function (response) {
                    if (response.data.success) {
                        self.SuccessMessage = 'Successfully Updated Location'
                        self.SuccessSnack = true;
                        self.belinoInventoryLocations = response.data.joinLocations.map(s => ({
                            al: s.al, li: s.li, locationName: self.getWareHouseLocation(s.al.al.merchant_id),
                            warehouse: s.li == null ? "Not Assigned" : (s.li.locationName == '' ? 'Not Assigned' : s.li.locationName)
                        }));
                        self.isLoading = false;
                    }
                    else {

                        self.isLoading = false;
                    }
                })


            },

            async UpdateRecords(args) {
                var self = this;
                if (args.previousValue.toLowerCase().trim() == args.value.toLowerCase().trim())
                    return false;
                else {

                    var rowData = args.rowData.al.al;
                    var rowInfo = self.$refs.grid.getRowInfo(args.cell);

                    var token = this.connectedAccounts.filter(x => x.merchantId == rowData.merchant_id)[0].token
                    await this.$http.post('/Inventory/UpdateSquareLocation', { UpdatedSquareLocation: rowData, Token: token }).then(function (response) {
                        if (response.data.success) {
                            self.belinoInventoryLocations.splice(rowInfo.rowIndex, 1, args.rowData);
                            self.SuccessMessage = 'Successfully Updated Item'
                            self.SuccessSnack = true;
                        }
                        else {
                            self.ErrorSnack = true;
                        }
                    })
                }
            },

            assignWarehouse: function (args) {
                if (args.item.id === 'editItems') {

                    var selectedItems = this.$refs.grid.getSelectedRecords();

                    if (selectedItems.length == 0) {
                        this.NoItemModal = true
                    }

                    else {

                        this.SelectedLocationId = '';
                        this.BulkEditModal = true;
                    }
                }
            }
        }
    }
</script>